import { Component } from '@angular/core';
import { CustomerDetailsService } from '../customer-details.service';
import { CommonModule } from '@angular/common';
import { AnalyticsBlockComponent } from '@enginuity/core/organisms/analytics-block/analytics-block.component';
import { TableFilterComponent } from '@enginuity/core/organisms/table-filter/table-filter.component';
import { TableViewComponent } from '@enginuity/core/organisms/table-view/table-view.component';
import { TableFooterComponent } from '@enginuity/core/organisms/table-footer/table-footer.component';
import { FooterInformation } from '@services/ui-services/models/footer-information';
import {
  CAMPAIGN_TABLE,
  FOOTER_LINKS,
} from '@services/customer-services/constants/customer-detail.constant';

@Component({
  selector: 'app-campaigns-tab',
  templateUrl: './campaigns-tab.component.html',
  styleUrls: ['./campaigns-tab.component.scss'],
  imports: [
    CommonModule,
    AnalyticsBlockComponent,
    TableFilterComponent,
    TableViewComponent,
    TableFooterComponent,
  ],
})
export class CampaignsTabComponent {
  data: string = '';

  footerData: FooterInformation = {
    isShowPagination: true,
    currentPage: 1,
    totalPages: 1,
    totalRows: 0,
    isShowSelected: true,
    selectedRows: 0,
  };
  footerLinks = FOOTER_LINKS;
  newData(data: string = 'Changed Data') {
    this.CustomerDetailsService.changeData(data); //invoke new Data
  }

  table_schema = CAMPAIGN_TABLE;

  constructor(private CustomerDetailsService: CustomerDetailsService) {}

  onSearchChange(searchData: string) {}

  onActionClick(event: any) {
    if (event.action === 'open_overlay') {
      //
    } else if (event.action === 'filter_search') {
      //
    }
  }

  setTableRows(customers: any) {
    const rows: any = [];
    for (const customer of customers) {
      rows.push([
        {
          column: this.table_schema.head[0].column,
          type: 'checkbox',
          label: '',
          isChecked: customer.isSelected,
          event: 'row-checkbox',
          data: customer,
        },
        {
          column: this.table_schema.head[1].column,
          type: 'customer',
          customer: {
            name: customer.admin.name,
            customer: customer,
          },
          actions: [
            {
              icon: 'more',
              text: '',
              active: false,
            },
          ],
        },

        {
          column: this.table_schema.head[2].column,
          type: 'text',
          text: {
            title: customer.name,
          },
        },
        {
          column: this.table_schema.head[3].column,
          type: 'text',
          text: {
            title: customer.type,
          },
        },
        {
          column: this.table_schema.head[4].column,
          type: 'text',
          text: {
            title: '',
          },
        },

        // {
        //   type: 'segments',
        //   segments: {
        //     icon: 'convert',
        //     segmentName: 'Email',
        //     active: false,
        //   },
        // },

        {
          column: this.table_schema.head[5].column,
          type: 'date',
          date: {
            title: customer.created_at,
            format: 'dd MMM, yyyy',
          },
        },
        {
          column: this.table_schema.head[6].column,
          type: 'date',
          date: {
            title: customer.created_at,
            format: 'dd MMM, yyyy hh:mm:ss a',
          },
        },
        {
          column: this.table_schema.head[7].column,
          type: 'actions',
          label: '',
          actions: [
            // {
            //   icon: 'more',
            //   text: '',
            //   active: false,
            // },
            {
              icon: 'chevron_right',
              text: '',
              active: false,
              action: 'open_overlay',
              action_id: customer.id,
            },
          ],
        },
      ]);
    }
    this.table_schema.rows = rows;
    return this.table_schema;
  }

  onSortColumn(event: any) {
    //this.setTableRows(this.table_schema.data);
  }
}
