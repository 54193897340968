import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownTopHeadingComponent } from '@enginuity/core/organisms/dropdown-top-heading/dropdown-top-heading.component';
import { TableViewComponent } from '@enginuity/core/organisms/table-view/table-view.component';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-order-tab',
  templateUrl: './order-tab.component.html',
  styleUrls: ['./order-tab.component.scss'],
  imports: [CommonModule, DropdownTopHeadingComponent, TableViewComponent, MatExpansionModule],
})
export class OrderTabComponent {
  activePanel: number | null = null; // Tracks the currently expanded panel

  setActivePanel(panelIndex: number): void {
    this.activePanel = this.activePanel === panelIndex ? null : panelIndex;
  }
  @Input()
  dropdown_panel: any = [
    {
      icon: 'transfer',
      title: 'Upcoming orders',
    },
    {
      icon: 'refresh',
      title: 'Past orders',
    },
  ];

  @Input()
  upcomingOrders: any = {
    show_rows: 1,
    head: [
      { type: 'orders', label: 'Order', sortable: true },
      { type: 'string', label: 'Settlement', sortable: true },
      { type: 'string', label: 'Shipment', sortable: true },
      { type: '', label: '', sortable: false },
    ],
    rows: [
      [
        {
          type: 'orders',
          orders: {
            number: 'OD1212132DSD212',
            storeIcons: 'amazon',
          },
        },
        {
          type: 'settlement',
          settlement: {
            label: 'Pending',
            Type: 'paused',
            IconType: 'dot',
          },
        },
        {
          type: 'status',
          status: {
            label: 'In 3 days ago',
            Type: 'tag',
            IconType: 'dot',
          },
        },

        {
          type: 'actions',
          label: '',
          actions: [
            {
              icon: 'more',
              text: '',
              active: false,
            },
            {
              icon: 'chevron_right',
              text: '',
              active: false,
            },
          ],
        },
      ],
      [
        {
          type: 'orders',
          orders: {
            number: 'OD1212132DSD212',
            storeIcons: 'amazon',
          },
        },
        {
          type: 'settlement',
          settlement: {
            label: 'Pending',
            Type: 'paused',
            IconType: 'dot',
          },
        },
        {
          type: 'status',
          status: {
            label: 'In 3 days ago',
            Type: 'tag',
            IconType: 'dot',
          },
        },

        {
          type: 'actions',
          label: '',
          actions: [
            {
              icon: 'more',
              text: '',
              active: false,
            },
            {
              icon: 'chevron_right',
              text: '',
              active: false,
            },
          ],
        },
      ],
      [
        {
          type: 'orders',
          orders: {
            number: 'OD1212132DSD212',
            storeIcons: 'amazon',
          },
        },
        {
          type: 'settlement',
          settlement: {
            label: 'Pending',
            Type: 'paused',
            IconType: 'dot',
          },
        },
        {
          type: 'status',
          status: {
            label: 'In 3 days ago',
            Type: 'tag',
            IconType: 'dot',
          },
        },

        {
          type: 'actions',
          label: '',
          actions: [
            {
              icon: 'more',
              text: '',
              active: false,
            },
            {
              icon: 'chevron_right',
              text: '',
              active: false,
            },
          ],
        },
      ],
      [
        {
          type: 'orders',
          orders: {
            number: 'OD1212132DSD212',
            storeIcons: 'amazon',
          },
        },
        {
          type: 'settlement',
          settlement: {
            label: 'Pending',
            Type: 'paused',
            IconType: 'dot',
          },
        },
        {
          type: 'status',
          status: {
            label: 'In 3 days ago',
            Type: 'tag',
            IconType: 'dot',
          },
        },

        {
          type: 'actions',
          label: '',
          actions: [
            {
              icon: 'more',
              text: '',
              active: false,
            },
            {
              icon: 'chevron_right',
              text: '',
              active: false,
            },
          ],
        },
      ],
    ],
  };
  @Input()
  pastOrders: any = {
    head: [
      { type: 'orders', label: 'Order', sortable: true },
      { type: 'string', label: 'Settlement', sortable: true },
      { type: 'string', label: 'Shipment', sortable: true },
      { type: '', label: '', sortable: false },
    ],
    rows: [
      [
        {
          type: 'orders',
          orders: {
            number: 'OD1212132DSD212',
            storeIcons: 'amazon',
          },
        },
        {
          type: 'settlement',
          settlement: {
            label: 'Settled',
            Type: 'completed',
            IconType: 'dot',
          },
        },
        {
          type: 'status',
          status: {
            label: '1 day early',
            Type: 'completed',
            IconType: 'dot',
          },
        },

        {
          type: 'actions',
          label: '',
          actions: [
            {
              icon: 'more',
              text: '',
              active: false,
            },
            {
              icon: 'chevron_right',
              text: '',
              active: false,
            },
          ],
        },
      ],
      [
        {
          type: 'orders',
          orders: {
            number: 'OD1212132DSD212',
            storeIcons: 'amazon',
          },
        },
        {
          type: 'settlement',
          settlement: {
            label: 'Settled',
            Type: 'completed',
            IconType: 'dot',
          },
        },
        {
          type: 'status',
          status: {
            label: 'Delayed',
            Type: 'cancelled',
            IconType: 'dot',
          },
        },

        {
          type: 'actions',
          label: '',
          actions: [
            {
              icon: 'more',
              text: '',
              active: false,
            },
            {
              icon: 'chevron_right',
              text: '',
              active: false,
            },
          ],
        },
      ],
    ],
  };
}
