import { Injectable } from '@angular/core';
import { from, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerDetailsService {
  private data = new BehaviorSubject('');
  data$ = this.data.asObservable();

  constructor() {}

  changeData(data: string) {
    this.data.next(data);
  }
  clearData(): void {
    this.data.next(''); // Clear the data when sidebar should be hidden
  }
}
