import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShipmentDetailsPanelComponent } from '../shipment-details-panel/shipment-details-panel.component';
import { BusinessDetailsComponent } from '../business-details-panel/business-details.component';
import { DropdownTopHeadingComponent } from '@enginuity/core/organisms/dropdown-top-heading/dropdown-top-heading.component';
import { PaymentDetailsPanelComponent } from '../payment-details-panel/payment-details-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-details',
  imports: [
    CommonModule,

    ShipmentDetailsPanelComponent,
    BusinessDetailsComponent,
    DropdownTopHeadingComponent,
    PaymentDetailsPanelComponent,
    MatExpansionModule,
  ],
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent {
  @Input()
  dropdown_panel: any = [
    {
      icon: 'bank',
      count: '',
      title: 'Customer details',
    },
    {
      icon: 'balance',
      count: '0',
      title: 'Payments',
    },
    {
      icon: 'truck',
      count: '0',
      title: 'Shipments',
    },
  ];

  activePanel: number | null = null; // Tracks the currently expanded panel

  setActivePanel(panelIndex: number): void {
    this.activePanel = this.activePanel === panelIndex ? null : panelIndex;
  }
}
