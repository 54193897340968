import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { AvatarComponent } from '@enginuity/core/molecules/avatar/avatar.component';
import { IconContainerComponent } from '@enginuity/core/atoms/icon-container/icon-container.component';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';

@Component({
  selector: 'app-activity-tab',
  templateUrl: './activity-tab.component.html',
  styleUrls: ['./activity-tab.component.scss'],
  imports: [
    CommonModule,
    SecondaryBtnsComponent,
    AvatarComponent,
    IconContainerComponent,
    CeIconComponent,
  ],
})
export class ActivityTabComponent {}
