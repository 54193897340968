<header>
  <div class="top-nav">
    <div class="breadcrumb-section d-flex align-items-center spacing-8">
      @if (topNavSetting === settings.OrganisationSetting) {
        <div class="breadcrumb-item h4-case f-14 dropdown">
          <app-badges
            [withIcon]="true"
            Type="tag"
            IconType="store"
            [label]="store?.name"
            [cdkMenuTriggerFor]="menu"
            class="example-standalone-trigger cursor-pointer"></app-badges>
          <ng-template #menu>
            <div class="dropdown-menus mt-3" cdkMenu>
              <app-profile
                cdkMenuItem
                [title]="'Store Name'"
                [menuItems]="storeNames"
                (onSelect)="handleStoreSelect($event)"></app-profile>
            </div>
          </ng-template>
        </div>
        <span class="seprate h4-reg primary-50">/</span>
      }
      <app-bredcrumb></app-bredcrumb>
    </div>
    @if (topNavSetting === settings.OrganisationSetting) {
      <div class="search-icon cursor-pointer" (click)="showSearchTab = !showSearchTab">
        <app-ce-icon [name]="'search'" [svg_class]="['primary-50']"></app-ce-icon>
      </div>
    }
    @if (topNavSetting === settings.OrganisationSetting) {
      <button
        class="h3-bold btn btn-secondary btn-small me-3 col-12 col-md-auto search-sec search-input"
        aria-label="button"
        role="button"
        (click)="openSearch()">
        <span class="left-icon btn-icon d-flex align-items-center spacing-2">
          <app-ce-icon [name]="'search'" [svg_class]="['primary-50']"></app-ce-icon>

          <span class="lable_name h4-reg text-nowrap primary-100">Search</span>
        </span>
        <span class="shortcut">
          <span class="lable_name h4-reg text-nowrap primary-60">&#x2318; K</span>
        </span>
      </button>

      <!-- <form
        [class.mobile_search]="showSearchTab"
        class="me-3 col-12 col-md-auto search-sec"
        role="search">
        <div class="" #searches>
          <div
            app-search
            [active]="false"
            class="w-100"
            [SearchBtn]="true"
            [disabled]="false"
            (onSearch)="onSearch($event)"
            size="large"></div>
        </div>
        <app-active-search
          [withIcon]="false"
          [searchResults]="searchResults"
          class="position-absolute"></app-active-search>
      </form> -->
    }
    <div class="header-right">
      <div class="text-end control-section">
        @if (topNavSetting === settings.OrganisationSetting) {
          <div class="site-status">
            <app-box-tab
              (selectedTab)="selectMode($event)"
              tabId="modeTab"
              Size="large"
              [boxContent]="applicationModeTabs"></app-box-tab>
          </div>
        }
        @if (topNavSetting === settings.OrganisationSetting) {
          <div class="notification dropdown">
            <div
              class="bell-icon position-relative"
              (click)="onStatus()"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin">
              <app-ce-icon
                [name]="'chat'"
                [size]="'20'"
                [svg_class]="['primary-100']"></app-ce-icon>
              <div class="notification-icon h5-reg">{{ messagesCount }}</div>
            </div>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="isOpen"
              [cdkConnectedOverlayHasBackdrop]="false"
              (backdropClick)="onOutsideClick()">
              <div class="dropdown-menus mt-3">
                <app-messages class="pb-0 pt-12" sender="Marta Doe"></app-messages>
              </div>
            </ng-template>
          </div>
        }
        @if (topNavSetting === settings.OrganisationSetting) {
          <div class="notification dropdown">
            <div
              class="bell-icon position-relative dropdown-toggle"
              (click)="onNotificaion()"
              cdkOverlayOrigin
              #trigger1="cdkOverlayOrigin">
              <app-ce-icon
                [name]="'notifications'"
                [size]="'20'"
                [svg_class]="['primary-100']"></app-ce-icon>

              <div class="notification-icon h5-reg">
                {{ notificationsCount }}
              </div>
            </div>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger1"
              [cdkConnectedOverlayOpen]="isNotification"
              [cdkConnectedOverlayHasBackdrop]="false"
              (backdropClick)="onOutsideClick()">
              <app-notifications
                class="dropdown-menus pb-0 pt-12"
                [tabContent]="tabContent"></app-notifications>
            </ng-template>
          </div>
        }

        <div class="flex-shrink-0 dropdown">
          <div
            class="d-flex align-items-center link-dark text-decoration-none cursor-pointer"
            (click)="user()"
            cdkOverlayOrigin
            #trigger2="cdkOverlayOrigin">
            @if (displayUserInfo().photo) {
              <app-avatar Type="image" userImg="{{ displayUserInfo().photo }}"></app-avatar>
            } @else {
              <app-avatar
                Type="text"
                short_name="{{ displayUserInfo().name | slice: 0 : 1 }}"></app-avatar>
            }
          </div>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger2"
            [cdkConnectedOverlayOpen]="isUser"
            [cdkConnectedOverlayHasBackdrop]="false"
            (backdropClick)="onOutsideClick()">
            <app-profile-dropdown
              [userImg]="displayUserInfo().photo"
              [userName]="displayUserInfo().name"
              [userEmail]="displayUserInfo().email"
              (linkClicked)="onProfileAction($event)"
              [themes]="timeContent"
              class="dropdown-menus pb-0 pt-8"></app-profile-dropdown>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  @if (applicationMode === 'Sandbox') {
    <app-info
      class="w-100"
      [Icons]="'tool'"
      [middle]="true"
      [label]="message"
      [Type]="'yellow'"
      [icon]="true"
      [close]="false"
      [show_button]="false"
      [Size]="'medium'"></app-info>
  }
</header>
