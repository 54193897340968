<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Live orders"
      metricsKey="live_order"
      [context]="context"
      [analytics]="orderAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Total orders"
      metricsKey="total_order"
      [context]="context"
      [analytics]="orderAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Completed orders"
      metricsKey="completed_order"
      [context]="context"
      [analytics]="orderAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>
