<div class="tab-details">
  <div
    class="subscription-tab tab-pane d-flex flex-column spacing-24"
    id="customer-subscriptions"
    role="tabpanel"
    aria-labelledby="subscriptions-tab">
    <div class="order-status">
      <div class="ce-row">
        <div class="ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12">
          <app-analytics-block
            [stautsTitle]="'Total subscriptions'"
            [price]="'5'"
            [graphImg]="'../assets/line-chart.svg'"
            [analytics]="{
              type: 'increase',
              week: 'Last week',
              status: { label: '4.2%', icon: false },
            }"></app-analytics-block>
        </div>
        <div class="ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12">
          <app-analytics-block
            [stautsTitle]="'Active'"
            [price]="'3'"
            [graphImg]="'../assets/line-chart.svg'"
            [analytics]="{
              type: 'decrease',
              week: 'Last week',
              status: { label: '4.2%', icon: false },
            }"></app-analytics-block>
        </div>
        <div class="ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12">
          <app-analytics-block
            [stautsTitle]="'Unpaid'"
            [price]="'1'"
            [graphImg]="'../assets/line-chart.svg'"
            [analytics]="{
              type: 'increase',
              week: 'Last week',
              status: { label: '4.2%', icon: false },
            }"></app-analytics-block>
        </div>
        <div class="ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12">
          <app-analytics-block
            [stautsTitle]="'Paused'"
            [price]="'1'"
            [graphImg]="'../assets/line-chart.svg'"
            [analytics]="{
              type: 'increase',
              week: 'Last week',
              status: { label: '4.2%', icon: false },
            }"></app-analytics-block>
        </div>
      </div>
    </div>
    <div class="ce-row">
      <div class="ce-col-12">
        <div class="table-component d-flex flex-column spacing-24">
          <div class="table-filter d-flex flex-column spacing-12">
            <app-table-filter></app-table-filter>
          </div>
          <app-table-view
            [table_schema]="table_schema"
            (onClick)="tableDataClick($event)"
            (onSort)="onSortColumn($event)"></app-table-view>
        </div>
      </div>
    </div>
  </div>
</div>

<footer class="layout-footer sticky-footer">
  <div class="ce-row">
    <div class="ce-col-12 pt-0 pb-0">
      <app-table-footer
        footerContainer
        class="w-100"
        [footerLinks]="footerLinks"
        [pageInfo]="footerData">
      </app-table-footer>
    </div>
  </div>
</footer>
<app-subscription-details [action]="openOverlay"></app-subscription-details>
