<div class="profile-dropdown border-radius radius-top-left">
  <h2 class="h4-case">{{ title }}</h2>
  <ul class="dropdown-items">
    @for (item of menuItems; track item; let i = $index) {
      <li>
        <label
          (click)="select(item)"
          [class.select]="item.active"
          class="form-check-label h3-bold"
          for="store{{ i }}"
          ><span class="h4-bold text-avtar">{{ item.icon }}</span
          >{{ item.name }}</label
        >
        @if (item.active) {
          <div class="green-dot"></div>
        }
        <!-- <app-checkbox class="d-none select-checkbox" [labelId]="'store'+i" *ngIf="item.selectable" [active]="false" [disabled]="false" [indeterminate]="false"></app-checkbox> -->
      </li>
    }
  </ul>
  @if (action_btn) {
    <app-link-btn
      class="mt-10"
      (custom_click)="createStore()"
      label="Create a new store"
      [active]="false"
      size="medium"
      IconType="add"></app-link-btn>
  }
</div>
