import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnalyticsRangeContext, ComparePeriod } from '@services/analytics-services/core';
import { DashboardStore } from '../dashboard/dashboard.store';
import { AnalyticsTrendWidgetComponent } from '@enginuity/analytics/organisms/analytics-trend-widget/analytics-trend-widget.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-dashboard-shipment',
  imports: [AnalyticsTrendWidgetComponent, AsyncPipe],
  templateUrl: './dashboard-shipment.component.html',
  styleUrl: './dashboard-shipment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardShipmentComponent {
  protected shipmentAnalytics$;
  protected comparePeriod!: ComparePeriod;
  protected context: AnalyticsRangeContext | undefined;

  constructor(private readonly dashboardStore: DashboardStore) {
    this.dashboardStore.getRangeContext().subscribe(context => this.fetchAnalytics(context));

    this.shipmentAnalytics$ = this.dashboardStore.getShippingAnalytics();
  }

  private fetchAnalytics(context: AnalyticsRangeContext) {
    this.context = context;
    this.dashboardStore.loadShippingAnalytics().subscribe();
  }
}
