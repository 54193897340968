<div class="tab-details">
  <div
    class="loyalty-tab tab-pane d-flex flex-column spacing-24"
    id="loyalty-tab-pane"
    role="tabpanel"
    aria-labelledby="loyalty-tab">
    <div class="order-status">
      <div class="ce-row">
        <div class="ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12">
          <app-analytics-block
            [stautsTitle]="'Tier'"
            [price]="'Silver'"
            [graphImg]="'../assets/line-chart.svg'"
            [analytics]="{
              type: 'neutral',
              week: 'points from Gold tier',
              status: { label: '50', icon: false },
            }"></app-analytics-block>
        </div>
        <div class="ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12">
          <app-analytics-block
            [stautsTitle]="'Total points'"
            [price]="'0'"
            [graphImg]="'../assets/line-chart.svg'"
            [analytics]="{
              type: 'neutral',
              week: 'last week',
              status: { label: '0.0%', icon: true },
            }"></app-analytics-block>
        </div>

        <div class="ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12">
          <app-analytics-block
            [stautsTitle]="'Points earned'"
            [price]="'2000'"
            [graphImg]="'../assets/line-chart.svg'"
            [analytics]="{
              type: 'neutral',
              week: 'last week',
              status: { label: '0.0%', icon: true },
            }"></app-analytics-block>
        </div>
        <div class="ce-col-lg-3 ce-col-md-3 ce-col-sm-6 ce-col-12">
          <app-analytics-block
            [stautsTitle]="'Points redemeed'"
            [price]="'2000'"
            [graphImg]="'../assets/line-chart.svg'"
            [analytics]="{
              type: 'increase',
              week: 'Last week',
              status: { label: '100.0%', icon: true },
            }"></app-analytics-block>
        </div>
      </div>
    </div>
    <div class="ce-row">
      <div class="ce-col-12">
        <div class="table-component d-flex flex-column spacing-24">
          <div class="table-filter d-flex flex-column spacing-12">
            <app-table-filter (custom_click)="onActionClick($event)"></app-table-filter>
          </div>
          <app-table-view
            [table_schema]="schema"
            (onClick)="onActionClick($event)"
            [loader]="customerLoyaltyLoader$ | async"
            [errorMessage]="errorMessage$ | async"
            (onSort)="onSortColumn($event)"></app-table-view>
        </div>
      </div>
    </div>
  </div>
  <footer class="layout-footer sticky-footer">
    <div class="ce-row">
      <div class="ce-col-12 pt-0 pb-0">
        <app-list-footer [pagination]="pagination" (onPageChange)="onPageChange($event)">
        </app-list-footer>
      </div>
    </div>
  </footer>
</div>
