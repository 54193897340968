<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Total payments value"
      metricsKey="total_payments_value"
      formatter="currency"
      [context]="context"
      [analytics]="paymentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Total payments"
      metricsKey="total_payments"
      [context]="context"
      [analytics]="paymentAnalytics$ | async"></app-analytics-trend-widget>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Total refunds"
      metricsKey="total_refunds"
      [context]="context"
      [analytics]="paymentAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>
