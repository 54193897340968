import { TableView } from '@services/ui-services/models/table-view';

export const FOOTER_LINKS = [
  {
    label: 'Finish selected',
    active: false,
    IconType: 'block',
    disabled: false,
    alert: false,
  },
  {
    label: 'Pause selected',
    active: false,
    IconType: 'jam_paused',
    disabled: false,
    alert: false,
  },
  {
    label: 'Export',
    active: false,
    IconType: 'download',
    disabled: false,
    alert: false,
  },
  {
    label: 'Delete',
    active: false,
    IconType: 'jam_trash_alt_f',
    disabled: false,
    alert: false,
  },
];

export const SHIPPING_TAB: any[] = [
  {
    label: 'Details',
    id: 'subdetails-tab-pane',
    icon: '/assets/subscription.svg',
    active: true,
  },
  {
    label: 'Order',
    id: 'suborder-tab-pane',
    icon: '/assets/info.svg',
    active: false,
  },
  {
    label: 'Activity',
    id: 'subactivity-tab-pane',
    icon: '/assets/info.svg',
    active: false,
  },
];

export const SUBSCRIPTION_TABLE: TableView = {
  head: [
    {
      type: 'checkbox',
      label: '',
      isShow: true,
      event: 'checkbox',
      isCheckIndeterminate: false,
    },
    { type: 'string', label: 'Plan', sortable: true, isShow: true },
    { type: 'string', label: 'Status', sortable: true, isShow: true },
    { type: 'string', label: 'Price', sortable: true, isShow: true },
    {
      type: 'string',
      label: 'Billing frequency',
      sortable: true,
      isShow: true,
    },
    { type: 'string', label: 'Created', sortable: true, isShow: true },
    { type: 'string', label: 'Next billing', sortable: true, isShow: true },
    { type: '', label: '', sortable: false, icon: '', isShow: true },
  ],
  rows: [
    [
      { type: 'checkbox', label: '' },
      {
        type: 'text',
        text: {
          title: 'Premium',
          cursor: 'pointer',
          color: 'red',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Overdue',
          Type: 'cancelled',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '₹ 29,900',
          cursor: '',
          color: 'red',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Annual',
          Type: 'tag',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '31 Oct, 2022',
          cursor: '',
          color: 'red',
        },
      },
      {
        type: 'text',
        text: {
          title: '5 days ago',
          cursor: '',
          color: 'red',
        },
      },

      {
        type: 'actions',
        label: '',
        actions: [
          {
            icon: 'chevron_right',
            text: '',
            active: false,
            action: 'open_overlay',
          },
        ],
      },
    ],
    [
      { type: 'checkbox', label: '' },
      {
        type: 'text',
        text: {
          title: 'Business',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Active',
          Type: 'completed',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '₹ 29,900',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Monthly',
          Type: 'tag',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '31 Oct, 2022',
        },
      },
      {
        type: 'text',
        text: {
          title: 'In 3 days',
          color: 'warning',
          cusrsor: '',
        },
      },

      {
        type: 'actions',
        label: '',
        actions: [
          {
            icon: 'more',
            text: '',
            active: false,
          },
          {
            icon: 'chevron_right',
            text: '',
            active: false,
            action: 'open_overlay',
          },
        ],
      },
    ],
    [
      { type: 'checkbox', label: '' },
      {
        type: 'text',
        text: {
          title: 'Enterprise',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Active',
          Type: 'completed',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '₹ 29,900',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Monthly',
          Type: 'tag',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '31 Oct, 2022',
        },
      },
      {
        type: 'text',
        text: {
          title: '5 days ago',
        },
      },

      {
        type: 'actions',
        label: '',
        actions: [
          {
            icon: 'more',
            text: '',
            active: false,
          },
          {
            icon: 'chevron_right',
            text: '',
            active: false,
            action: 'open_overlay',
          },
        ],
      },
    ],
    [
      { type: 'checkbox', label: '' },
      {
        type: 'text',
        text: {
          title: 'Premium',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Active',
          Type: 'completed',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '₹ 29,900',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Monthly',
          Type: 'tag',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '31 Oct, 2022',
        },
      },
      {
        type: 'text',
        text: {
          title: '5 days ago',
        },
      },

      {
        type: 'actions',
        label: '',
        actions: [
          {
            icon: 'more',
            text: '',
            active: false,
          },
          {
            icon: 'chevron_right',
            text: '',
            active: false,
            action: 'open_overlay',
          },
        ],
      },
    ],
    [
      { type: 'checkbox', label: '' },
      {
        type: 'text',
        text: {
          title: 'Premium',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Active',
          Type: 'completed',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '₹ 29,900',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Monthly',
          Type: 'tag',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '31 Oct, 2022',
        },
      },
      {
        type: 'text',
        text: {
          title: '5 days ago',
        },
      },

      {
        type: 'actions',
        label: '',
        actions: [
          {
            icon: 'more',
            text: '',
            active: false,
          },
          {
            icon: 'chevron_right',
            text: '',
            active: false,
            action: 'open_overlay',
          },
        ],
      },
    ],
    [
      { type: 'checkbox', label: '' },
      {
        type: 'text',
        text: {
          title: 'Premium',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Active',
          Type: 'completed',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '₹ 29,900',
        },
      },
      {
        type: 'status',
        status: {
          label: 'Monthly',
          Type: 'tag',
          IconType: 'no_icon',
        },
      },
      {
        type: 'text',
        text: {
          title: '31 Oct, 2022',
        },
      },
      {
        type: 'text',
        text: {
          title: '5 days ago',
        },
      },

      {
        type: 'actions',
        label: '',
        actions: [
          {
            icon: 'more',
            text: '',
            active: false,
          },
          {
            icon: 'chevron_right',
            text: '',
            active: false,
            action: 'open_overlay',
          },
        ],
      },
    ],
  ],
  data: [],
};
