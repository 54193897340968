export const NAVIGATIONS = [
  { name: 'Home', icon: 'home', index: 'Home', route: 'home/overview' },
  { name: 'Dashboard', icon: 'home', index: 'Dashboard', route: 'home/overview' },
  { name: 'Customers', icon: 'person', index: 'Customers', route: 'customers' },
  { name: 'Visitors', icon: 'person', index: 'Visitors', route: 'visitors' },
  { name: 'Products', icon: 'box', index: 'Products', route: 'products' },
  { name: 'Categories', icon: 'list', index: 'Categories', route: 'products/categories' },
  { name: 'Attributes', icon: 'tasks', index: 'Attributes', route: 'products/attributes' },
  { name: 'Orders', icon: 'cart', index: 'Orders', route: 'orders' },
  { name: 'Shipments', icon: 'truck', index: 'Shipments', route: 'shipments' },
  { name: 'Payments', icon: 'bank', index: 'Payments', route: 'payments' },
  { name: 'Coupons', icon: 'coupon', index: 'Coupons', route: 'coupons' },
  { name: 'Promotions', icon: 'percentage', index: 'Promotions', route: 'promotions' },
  { name: 'Analytics', icon: 'bar_graph', index: 'Analytics', route: 'analytics' },
  { name: 'Live events', icon: 'subscription', index: 'Events', route: 'analytics/live-events' },
  { name: 'Campaigns', icon: 'filter', index: 'Campaigns', route: 'campaigns' },
  { name: 'Media', icon: 'camera', index: 'Media', route: 'media' },
  //Organisations
  {
    name: 'Organisation settings',
    icon: 'settings',
    index: 'Settings',
    route: 'organisation/stores',
  },
  { name: 'View stores', icon: 'settings', index: 'Settings', route: 'organisation/stores' },
  { name: 'View profile', icon: 'settings', index: 'Settings', route: 'organisation/profile' },
  { name: 'View account', icon: 'settings', index: 'Settings', route: 'organisation/account' },
  { name: 'Manage Team', icon: 'settings', index: 'Settings', route: 'organisation/users' },
  //Settings
  { name: 'Store setting', icon: 'settings', index: 'Settings', route: 'settings/general' },
  {
    name: 'Customer settings',
    icon: 'settings',
    index: 'Settings',
    route: 'settings/customer-options',
  },
  { name: 'Brand settings', icon: 'settings', index: 'Settings', route: 'settings/brand' },
  {
    name: 'Subscription settings',
    icon: 'settings',
    index: 'Settings',
    route: 'settings/subscriptions',
  },
  { name: 'Manage CMS integration', icon: 'settings', index: 'Settings', route: 'settings/cms' },
  {
    name: 'Shipping settings',
    icon: 'settings',
    index: 'Settings',
    route: 'settings/shipping/general',
  },
  { name: 'Payment settings', icon: 'settings', index: 'Settings', route: 'settings/payments' },
  {
    name: 'Analytics settings',
    icon: 'settings',
    index: 'Settings',
    route: 'settings/event-schema',
  },
  {
    name: 'Analytics settings - Advanced',
    icon: 'settings',
    index: 'Settings',
    route: 'settings/advanced-setup',
  },
  {
    name: 'Notification settings',
    icon: 'settings',
    index: 'Notifications',
    route: 'settings/notification',
  },
  { name: 'Loyalty settings', icon: 'settings', index: 'Settings', route: 'settings/loyalty' },
  {
    name: 'Developer settings',
    icon: 'settings',
    index: 'Developers',
    route: 'settings/developers',
  },
];
