<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="To be shipped"
      metricsKey="to_be_shipped"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="In transit"
      metricsKey="in_transit"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="On hold"
      metricsKey="on_hold"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Delivered"
      metricsKey="delivered"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Late delivered"
      metricsKey="late_delivered"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>

  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Returned to origin"
      metricsKey="returned_to_origin"
      [context]="context"
      [analytics]="shipmentAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>
