import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AnalyticsRangeContext } from '@services/analytics-services/core';
import { AsyncPipe } from '@angular/common';
import { AnalyticsTrendWidgetComponent } from '@enginuity/analytics/organisms/analytics-trend-widget/analytics-trend-widget.component';
import { DashboardStore } from '../dashboard/dashboard.store';

@Component({
  selector: 'app-dashboard-customers',
  imports: [AsyncPipe, AnalyticsTrendWidgetComponent],
  templateUrl: './dashboard-customers.component.html',
  styleUrl: './dashboard-customers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCustomersComponent implements OnInit {
  protected customerAnalytics$;
  protected context: AnalyticsRangeContext | undefined;

  constructor(private readonly dashboardStore: DashboardStore) {
    this.dashboardStore.getRangeContext().subscribe(context => this.fetchAnalytics(context));
    this.customerAnalytics$ = this.dashboardStore.getCustomerAnalytics();
  }

  ngOnInit() {}

  private fetchAnalytics(context: AnalyticsRangeContext) {
    this.context = context;
    this.dashboardStore.loadCustomerAnalytics().subscribe();
  }
}
