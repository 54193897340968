import { TableViewHeader } from '@services/ui-services/models';
import { OrderProductStats } from '@services/analytics-services/orders/models/order-product';

type mapCustomerProductTableRow = OrderProductStats & {
  isSelected?: boolean;
};

type TableHead = Omit<TableViewHeader, 'column'> & {
  column?: keyof mapCustomerProductTableRow;
};

export const TopProductList: TableHead[] = [
  {
    type: 'string',
    label: 'Name',
    sortable: true,
    isShow: true,
    column: 'product_name',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Qty sold',
    sortable: true,
    isShow: true,
    column: 'quantity',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Total value',
    sortable: true,
    isShow: true,
    column: 'average_selling_price',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Order count',
    sortable: true,
    isShow: true,
    column: 'order_count',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Customer count',
    sortable: true,
    isShow: true,
    column: 'customer_count',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Avg. selling price',
    sortable: true,
    isShow: true,
    column: 'average_selling_price',
    sortOrder: 'default',
  },
  {
    type: 'actions',
    label: '',
    sortable: false,
    isShow: true,
    sortOrder: 'default',
  },
];
