<div
  class="order-block p-12"
  [class.without-container]="state == 'without-container'"
  [class.hover]="state == 'hover'">
  <div class="media d-flex align-items-center spacing-12">
    @if (container_icon) {
      <div class="media-left">
        <span class="bank-icon">
          <app-icon-container
            Type="icon"
            class="primary-50"
            IconType="{{ container_icon }}"
            background_color="primary-20"></app-icon-container>
        </span>
      </div>
    }
    <div
      class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
      <h4 class="h4-bold m-0 p-0 primary-100 d-flex align-items-center spacing-8">
        {{ order }}
        @if (store) {
          <app-ce-icon
            [svg_class]="['d-flex', 'primary-100']"
            name="{{ store_icon }}"></app-ce-icon>
        }
      </h4>
      <span class="h4-reg primary-60">{{ date }}</span>
    </div>
    <div class="price h4-bold primary-100 d-flex align-items-center spacing-8 justify-content-end">
      <div class="d-flex flex-column align-items-end justify-content-end spacing-2">
        @if (price) {
          <div class="d-flex align-items-start">
            ₹ {{ amount }}
            <span class="h4-reg primary-60">/ {{ tamount }}</span>
          </div>
        }
        <app-badges
          [class.hide]="!status"
          [withIcon]="true"
          Type="{{ statusType }}"
          [IconType]="status_icon"
          [label]="status_label"></app-badges>
      </div>
      @if (view_detail == true) {
        <app-action-btn [active]="false" type="button" IconType="chevron_right"></app-action-btn>
      }
    </div>
  </div>
</div>
