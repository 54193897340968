<div class="ce-row">
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="Total customers"
      metricsKey="total_customers"
      [context]="context"
      [analytics]="customerAnalytics$ | async"></app-analytics-trend-widget>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="New customers"
      metricsKey="new_customers"
      [context]="context"
      [analytics]="customerAnalytics$ | async"></app-analytics-trend-widget>
  </div>
  <div class="ce-col-lg-4 ce-col-md-12 ce-col-sm-12 ce-col-12">
    <app-analytics-trend-widget
      title="CLV"
      metricsKey="customer_lifetime_value"
      formatter="currency"
      [context]="context"
      [analytics]="customerAnalytics$ | async"></app-analytics-trend-widget>
  </div>
</div>
