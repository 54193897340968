/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { FooterInformation } from '@services/ui-services/models/footer-information';
import { TableView } from '@services/ui-services/models/table-view';

@Injectable({
  providedIn: 'root',
})
export class TableHelperService {
  constructor() {}

  setFooter(footerData: FooterInformation, response: any, data: any) {
    const previousPage = response.content.pagination.previous_page || 0;
    //const nextPage = response.content.pagination.next_page || 1;

    footerData.selectedRows = 0;
    footerData.currentPage = data.length ? previousPage + 1 : 1;
    footerData.totalPages = response.content.pagination.total_pages || 1;
    footerData.totalRows = response.content.pagination.total_records || data.length || 0;
    let from =
      response.content.pagination.limit * footerData.currentPage -
      response.content.pagination.limit +
      1;

    let to = response.content.pagination.limit * footerData.currentPage;
    footerData.showingRowsCount = from + '-' + to || 0;
  }

  getIndeterminate(table_schema: TableView) {
    return (
      table_schema.data.filter((customer: any) => customer.isSelected === false).length !== 0 &&
      table_schema.data.filter((customer: any) => customer.isSelected === true).length !== 0
    );
  }

  getHeadCheck(table_schema: TableView) {
    return (
      table_schema.data.filter((customer: any) => customer.isSelected === true).length ===
      table_schema.data.length
    );
  }

  resetHeader(table_schema: TableView) {
    table_schema.head[0].isCheckIndeterminate = this.getIndeterminate(table_schema);
    table_schema.head[0].isChecked = this.getHeadCheck(table_schema);
  }

  getFooter(data: any, pagination: any, selected: any) {
    const previousPage = pagination?.previous_page || 0;
    const currentPage = data?.length ? previousPage + 1 : 1;
    const from = pagination?.limit! * currentPage - pagination?.limit! + 1 || 0;
    const to = from + data?.length - 1 || 0;

    return {
      isShowPagination: true,
      currentPage: currentPage,
      totalPages: pagination?.total_pages,
      selectedRows: selected!.length,
      totalRows: pagination?.total_records,
      isShowSelected: true,
      isShowShowing: true,
      showingRowsCount: from + '-' + to || 0,
    };
  }
}
