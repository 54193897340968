<div class="products-list d-flex flex-column spacing-24 primary-100">
  @if (show_b2b_price) {
    <div class="total-price d-flex align-items-center flex-wrap">
      <!--TODO: why we used it here-->
      <!-- <div class="price-col">
        <span class="primary-60 h4-reg">Mrp:</span>
        <div class="price h4-bold primary-100">
         {{ summary?.mrp | currency }}
        </div>
      </div> -->
      <!-- <div class="price-col">
        <span class="primary-60 h4-reg">Tax:</span>
        <div class="price h4-bold primary-100">{{ summary?.total_tax | currency}}
        </div>
      </div>
      <div class="price-col">
        <span class="primary-60 h4-reg">Rate:</span>
        <div class="price h4-bold primary-100">{{ summary?.order_items?.tax_rate | currency }}
        </div>
      </div> -->
    </div>
  }
  <div class="subTotal-sec d-flex flex-wrap flex-column spacing-8">
    <div class="d-flex align-items-center justify-content-between">
      <span class="h4-reg primary-60">Subtotal:</span>
      <span class="h4-reg primary-60">{{ summary?.sub_total | currency }}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <span class="h4-reg primary-60">GST:</span>
      <span class="h4-reg primary-60">{{ summary?.total_tax | currency }}</span>
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <span class="h4-reg primary-60">Shipping:</span>
      <span class="h4-reg primary-60">{{ summary?.shipping_amount | currency }}</span>
    </div>
    @if (summary?.is_coupon_applied) {
      <div class="d-flex align-items-center justify-content-between">
        <span class="h4-reg primary-100 d-flex align-items-center spacing-4"
          >Coupon:
          @if (summary?.coupon_code) {
            <app-badges
              [withIcon]="false"
              Type="tag"
              IconType="dot"
              label="{{ summary?.coupon_code }}"></app-badges>
          } @else if (!summary?.coupon_code) {
            <app-badges [withIcon]="false" Type="tag" IconType="dot" label="null"></app-badges>
          }
        </span>
        <span class="h4-reg primary-60"> {{ summary?.coupon_discount_amount | currency }}</span>
      </div>
    }
    <div class="d-flex align-items-center justify-content-between">
      <span class="h4-bold primary-100 text-uppercase">Total</span>
      <span class="h4-bold primary-100">{{ summary?.grand_total | currency }}</span>
    </div>
  </div>
</div>
