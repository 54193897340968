import { TableViewHeader } from '@services/ui-services/models';
import { LoyaltyPointActivity } from '../models/loyaltiy-activity';

type mapLoyaltyActivityTableRow = LoyaltyPointActivity & {
  isSelected?: boolean;
};

type TableHead = Omit<TableViewHeader, 'column'> & {
  column?: keyof mapLoyaltyActivityTableRow;
};

export const loyaltyActivityList: TableHead[] = [
  {
    type: 'string',
    label: 'Title',
    sortable: true,
    isShow: true,
    column: 'title',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Activity type',
    sortable: true,
    isShow: true,
    column: 'activity_type',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Points',
    sortable: true,
    isShow: true,
    column: 'loyalty_point',
    sortOrder: 'default',
  },
  {
    type: 'string',
    label: 'Activity',
    sortable: false,
    isShow: true,
    column: 'activity',
    sortOrder: 'default',
  },
];
