import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { AvatarIconComponent } from '@enginuity/core/molecules/avatar-icon/avatar-icon.component';
import { IconContainerComponent } from '@enginuity/core/atoms/icon-container/icon-container.component';
import { GlobalSearchService } from '@services/core-services/global-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CmdkModule } from '@ngxpert/cmdk';
import { GlobalCommandsService } from '@services/core-services/global-command.service';
import { SUGGESTIONS } from '@services/global-search-services/constants/suggestion';
import { COMMANDS, MODE, THEME } from '@services/global-search-services/constants/commands';
import { ApplicationMode, AppService } from '@services/core-services/app.service';
import { GlobalLoaderService, LoaderState } from '@services/core-services/global-loader.service';
import { AuthService } from '@services/auth-services/auth.service';
import { NAVIGATIONS } from '@services/global-search-services/constants/navigations';
import { NotifyService } from '@services/core-services/notify.service';

@Component({
  selector: 'app-active-search',
  templateUrl: './active-search.component.html',
  styleUrls: ['./active-search.component.scss'],
  imports: [
    CommonModule,
    CeIconComponent,
    AvatarIconComponent,
    IconContainerComponent,
    CmdkModule,
    MatDialogModule,
  ],
})
export class ActiveSearchComponent implements OnInit, OnDestroy {
  private globalSubscription: Subscription | undefined;
  public applicationMode: ApplicationMode = this.appService.getApplicationMode();
  public navigations = NAVIGATIONS;
  public suggestion = SUGGESTIONS;
  public commands = COMMANDS;
  public themes = THEME;
  public modes = MODE;
  // filteredSuggestions: { name: string; icon: string; key: string; index: string }[] = [];

  readonly dialog = inject(MatDialog);
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() searchResults: any[] = [];
  searchSubject = new Subject<string>();
  @Input() showTabs: any = 'all';
  value = 'Open Customer Search';
  pages: Array<string> = ['home'];
  activeIndex = null;
  activatedurl: any;
  searchData: any;
  get activePage() {
    return this.pages[this.pages.length - 1];
  }
  get isHome() {
    return this.activePage === 'home';
  }
  setValue(value: string) {
    this.value = value;
  }
  constructor(
    private readonly globalSearch: GlobalSearchService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly globalCommandsService: GlobalCommandsService,
    private readonly appService: AppService,
    private readonly loaderService: GlobalLoaderService,
    private readonly authService: AuthService,
    private notify: NotifyService
  ) {
    const fullUrl = this.router.routerState.snapshot.url;
    const urlSegments = fullUrl.split('/');
    const targetSegment = urlSegments.find(segment =>
      ['customers', 'orders', 'products', 'category'].includes(segment)
    );
    this.activatedurl = targetSegment;
    this.searchSubject
      .pipe(
        // debounceTime(300), // Debounce the search by 300ms
        distinctUntilChanged(), // Only trigger search if the input changes
        switchMap(searchQuery => {
          const query: any = { search: searchQuery };
          if (targetSegment) {
            query['index'] =
              targetSegment.charAt(0).toUpperCase() + targetSegment.slice(1).toLowerCase();
            query['limit'] = 1000;
          } else if (this.activeIndex) {
            query['index'] = this.activeIndex;
          }
          return this.globalSearch.getSearchData(query);
        })
      )
      .subscribe({
        next: results => {
          this.searchResults = results.results;
        },
        error: err => {
          this.searchResults = [];
          console.error('Error fetching search results:', err);
        },
      });
  }

  itemClick(event: any) {
    this.activeIndex = event.index;
    this.searchData = [];
    this.searchResults = [];
    if (this.activeIndex == 'Logout') {
      this.authService.logout().then(res => {
        this.router.navigate(['auth', 'login']);
      });
      this.dialog.closeAll();
    }
  }
  menuClick(event: any) {
    this.router.navigate([`/${event.route}`]);
    this.dialog.closeAll();
  }
  ngOnInit() {
    this.globalSubscription = this.globalCommandsService.shortcutTriggered.subscribe(command => {
      switch (command) {
        case 'clearSearch':
          this.clearSearch();
          break;
        // Handle other commands if needed
      }
    });
    this.suggestion;
    this.commands;
    this.themes;
    this.modes;
  }

  ngOnDestroy() {
    if (this.globalSubscription) {
      this.globalSubscription.unsubscribe();
    }
  }

  clearSearch() {
    if (this.searchData.length === 0) {
      this.searchResults = [];
      this.activeIndex = null;
    }
  }

  allCustomers() {}
  allProducts() {}
  allOrders() {}

  onCommandSelect(event: any) {}

  setSearch(event: any) {
    const searchQuery = (event.target as HTMLInputElement)?.value || '';
    this.searchData = searchQuery;
    if (searchQuery) {
      this.searchSubject.next(searchQuery);
    }
    if (this.searchData.length == 0) {
      this.activeIndex = null;
      this.searchResults = [];
    } else {
      this.searchResults = [];
    }
  }
  changeTheme(event: any) {
    const themMode = event.index;
    this.appService.setThemeMode(themMode);
    this.dialog.closeAll();
  }
  changeMode(event: any) {
    const applicationMode = event.index;
    if (this.applicationMode == applicationMode) {
      this.notify.success(`The application is already in the ${applicationMode} Mode`);
      return;
    }
    this.updateLoaderMessages(this.applicationMode);
    this.appService.setApplicationMode(applicationMode);
    this.dialog.closeAll();
  }
  onOrder(event: any) {
    this.router.navigate([`orders/${event}/summary`]);
    this.dialog.closeAll();
  }
  onClick(event: any) {
    console.log(event);
  }
  onCustomer(event: any) {
    this.router.navigate([`customers/${event}/overview`]);
    this.dialog.closeAll();
  }
  onProduct(event: any, variant: any = null) {
    this.router.navigate([`products/${event}/${variant ? 'variants' : 'overview'}`]);
    this.dialog.closeAll();
  }

  filterIndex(value: any, search: any): boolean {
    const suggestionNames = (SUGGESTIONS || []).map(s => s.name);
    const commandNames = (COMMANDS || []).map(s => s.name);
    const navigations = (NAVIGATIONS || []).map(s => s.name);
    const validValues = [...suggestionNames, ...commandNames, ...navigations];
    if (validValues.includes(value)) {
      value = value.toLowerCase();
      search = search.toLowerCase();
      if (value.indexOf(search) == -1) {
        return false;
      }
    }
    return true;
  }

  private updateLoaderMessages(mode: ApplicationMode) {
    if (mode === ApplicationMode.Live) {
      this.loaderService.setContent({
        title: '🔧 Switching to Sandbox Mode…',
        message:
          'Hold tight! We’re setting up your sandbox for safe testing. You’ll be back in the testing environment in no time!',
      });
    } else if (mode === ApplicationMode.Sandbox) {
      this.loaderService.setContent({
        title: '🚀 Switching to Live Mode…',
        message:
          'Get ready! We’re powering up for live operations. This will just take a moment as we prepare everything for real-time action!',
      });
    }

    this.loaderService.setStatus(LoaderState.InProgress);
    setTimeout(() => {
      this.loaderService.setStatus(LoaderState.None); // Hide loader after a delay (you can adjust the delay)
    }, 1000);
  }
}
