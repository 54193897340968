<div class="modal-slide">
  <div class="accordian">
    <mat-accordion [multi]="false" class="example-headers-align">
      <mat-expansion-panel
        *ngFor="let panel of dropdown_panel; let i = index"
        [expanded]="activePanel === i"
        (opened)="setActivePanel(i)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <app-dropdown-top-heading
              [accordian_heading]="panel.title"
              [count]="panel.count"
              [icon]="panel.icon"
              [background]="'primary-80'">
            </app-dropdown-top-heading>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-business-details *ngIf="panel.title === 'Customer Details'"></app-business-details>
        <app-payment-details-panel *ngIf="panel.title === 'Payments'"></app-payment-details-panel>
        <app-shipment-details-panel
          *ngIf="panel.title === 'Shipments'"></app-shipment-details-panel>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
