export enum PaymentAllowedActions {
  RefundPayment = 'refund',
  CancelPayment = 'cancel',
  SendReminder = 'send reminder',
}

export enum PaymentAllowedActionsLabel {
  RefundPayment = 'Refund Payment',
  CancelPayment = 'Cancel Payment',
  SendReminder = 'Send Reminder',
}
