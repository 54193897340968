<div class="accordion-content">
  <div class="nested-accordion">
    <div
      data-bs-toggle="collapse"
      href="#nested1"
      role="button"
      aria-expanded="false"
      aria-controls="nested1"
      class="dropdown-toggle">
      <div class="media d-flex align-items-center spacing-16">
        <div class="media-left">
          <span class="product-icon"><img alt="" src="/assets/product.jpg" /></span>
        </div>
        <div
          class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
          <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-8">
            FedEx (Standard)
            <app-badges [withIcon]="true" Type="live" IconType="dot" label="live"></app-badges>
          </h4>
          <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
            <span class="h4-reg primary-60">#234515613514</span>
            <span class="dot-divder"></span>
            <span class="h4-reg primary-60">14 Nov, 2022</span>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse" id="nested1">
      <div class="payment-widget d-flex flex-column spacing-16 pt-16">
        <div class="border-line"></div>
        <div class="subTotal-sec d-flex flex-wrap flex-column spacing-12">
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8"
              >Shipment address</span
            >
            <span class="h4-bold primary-100">Address street 22</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Promised DD</span>
            <span class="h3-bold primary-100">20 Sep, 2022</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Estimated DD</span>
            <span class="h3-bold primary-100">20 Sep, 2022</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-bold primary-100"> Packaging</span>
            <app-link-btn
              label="View packaging details"
              [active]="false"
              size="medium"
              IconType="chevron_right"
              [disabled]="false"></app-link-btn>
          </div>
          <div class="d-flex align-items-center justify-content-between spacing-12 box-sec">
            <div class="box cursor-pointer w-100">
              <div class="media d-flex align-items-center spacing-16">
                <div class="media-left">
                  <app-icon-container
                    Type="icon"
                    class="primary-50"
                    IconType="card"
                    background_color="primary-20"></app-icon-container>
                </div>
                <div
                  class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
                  <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-4">
                    Box 1
                    <span class="bank-icon h4-bold primary-60">x2</span>
                  </h4>
                  <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
                    <span class="h4-reg primary-60">Packed 8 items</span>
                    <span class="dot-divder"></span>
                    <span class="h4-reg primary-60">10 kg</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box cursor-pointer w-100">
              <div class="media d-flex align-items-center spacing-16">
                <div class="media-left">
                  <app-icon-container
                    Type="icon"
                    class="primary-50"
                    IconType="card"
                    background_color="primary-20"></app-icon-container>
                </div>
                <div
                  class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
                  <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-4">
                    Box 1
                    <span class="bank-icon h4-bold primary-60">x2</span>
                  </h4>
                  <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
                    <span class="h4-reg primary-60">Packed 8 items</span>
                    <span class="dot-divder"></span>
                    <span class="h4-reg primary-60">10 kg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions w-100 d-flex align-items-center flex-column spacing-4 pt-16">
            <app-buttons
              class="w-100"
              [active]="false"
              [disabled]="false"
              label="Download shipment label"
              size="large"
              Type="withoutIcon"></app-buttons>

            <app-secondary-btns
              class="w-100"
              [active]="false"
              [disabled]="false"
              label="Download shipment invoice"
              size="large"
              Type="leftIcon"
              IconValue="⌘N"
              IconType="download"></app-secondary-btns>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="accordion-content">
  <div class="nested-accordion">
    <div
      data-bs-toggle="collapse"
      href="#nested2"
      role="button"
      aria-expanded="false"
      aria-controls="nested2"
      class="dropdown-toggle">
      <div class="media d-flex align-items-center spacing-16">
        <div class="media-left">
          <span class="product-icon"><img alt="" src="/assets/product.jpg" /></span>
        </div>
        <div
          class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
          <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-8">
            FedEx (Standard)
            <app-badges [withIcon]="true" Type="live" IconType="dot" label="live"></app-badges>
          </h4>
          <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
            <span class="h4-reg primary-60">#234515613514</span>
            <span class="dot-divder"></span>
            <span class="h4-reg primary-60">14 Nov, 2022</span>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse" id="nested2">
      <div class="payment-widget d-flex flex-column spacing-16 pt-16">
        <div class="border-line"></div>
        <div class="subTotal-sec d-flex flex-wrap flex-column spacing-12">
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8"
              >Shipment address</span
            >
            <span class="h4-bold primary-100">Address street 22</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Promised DD</span>
            <span class="h3-bold primary-100">20 Sep, 2022</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Estimated DD</span>
            <span class="h3-bold primary-100">20 Sep, 2022</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-bold primary-100"> Packaging</span>
            <app-link-btn
              label="View packaging details"
              [active]="false"
              size="medium"
              IconType="chevron_right"
              [disabled]="false"></app-link-btn>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="box cursor-pointer">
              <div class="media d-flex align-items-center spacing-16">
                <div class="media-left">
                  <app-icon-container
                    Type="icon"
                    class="primary-50"
                    IconType="card"
                    background_color="primary-20"></app-icon-container>
                </div>
                <div
                  class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
                  <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-4">
                    Box 1
                    <span class="bank-icon h4-bold primary-60">x2</span>
                  </h4>
                  <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
                    <span class="h4-reg primary-60">Packed 8 items</span>
                    <span class="dot-divder"></span>
                    <span class="h4-reg primary-60">10 kg</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box cursor-pointer">
              <div class="media d-flex align-items-center spacing-16">
                <div class="media-left">
                  <app-icon-container
                    Type="icon"
                    class="primary-50"
                    IconType="card"
                    background_color="primary-20"></app-icon-container>
                </div>
                <div
                  class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
                  <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-4">
                    Box 1
                    <span class="bank-icon h4-bold primary-60">x2</span>
                  </h4>
                  <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
                    <span class="h4-reg primary-60">Packed 8 items</span>
                    <span class="dot-divder"></span>
                    <span class="h4-reg primary-60">10 kg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions w-100 d-flex align-items-center flex-column spacing-4 pt-24">
            <app-buttons
              class="w-100"
              [active]="false"
              [disabled]="false"
              label="Download shipment label"
              size="large"
              Type="withoutIcon"></app-buttons>

            <app-secondary-btns
              class="w-100"
              [active]="false"
              [disabled]="false"
              label="Download shipment invoice"
              size="large"
              Type="leftIcon"
              IconValue="⌘N"
              IconType="download"></app-secondary-btns>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="accordion-content">
  <div class="nested-accordion">
    <div
      data-bs-toggle="collapse"
      href="#nested3"
      role="button"
      aria-expanded="false"
      aria-controls="nested3"
      class="dropdown-toggle">
      <div class="media d-flex align-items-center spacing-16">
        <div class="media-left">
          <span class="product-icon"><img alt="" src="/assets/product.jpg" /></span>
        </div>
        <div
          class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
          <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-8">
            FedEx (Standard)
            <app-badges [withIcon]="true" Type="live" IconType="dot" label="live"></app-badges>
          </h4>
          <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
            <span class="h4-reg primary-60">#234515613514</span>
            <span class="dot-divder"></span>
            <span class="h4-reg primary-60">14 Nov, 2022</span>
          </div>
        </div>
      </div>
    </div>
    <div class="collapse" id="nested3">
      <div class="payment-widget d-flex flex-column spacing-16 pt-16">
        <div class="border-line"></div>
        <div class="subTotal-sec d-flex flex-wrap flex-column spacing-12">
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8"
              >Shipment address</span
            >
            <span class="h4-bold primary-100">Address street 22</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Promised DD</span>
            <span class="h3-bold primary-100">20 Sep, 2022</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Estimated DD</span>
            <span class="h3-bold primary-100">20 Sep, 2022</span>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <span class="h4-bold primary-100"> Packaging</span>
            <app-link-btn
              label="View packaging details"
              [active]="false"
              size="medium"
              IconType="chevron_right"
              [disabled]="false"></app-link-btn>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="box cursor-pointer">
              <div class="media d-flex align-items-center spacing-16">
                <div class="media-left">
                  <app-icon-container
                    Type="icon"
                    class="primary-50"
                    IconType="card"
                    background_color="primary-20"></app-icon-container>
                </div>
                <div
                  class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
                  <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-4">
                    Box 1
                    <span class="bank-icon h4-bold primary-60">x2</span>
                  </h4>
                  <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
                    <span class="h4-reg primary-60">Packed 8 items</span>
                    <span class="dot-divder"></span>
                    <span class="h4-reg primary-60">10 kg</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="box cursor-pointer">
              <div class="media d-flex align-items-center spacing-16">
                <div class="media-left">
                  <app-icon-container
                    Type="icon"
                    class="primary-50"
                    IconType="card"
                    background_color="primary-20"></app-icon-container>
                </div>
                <div
                  class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
                  <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-4">
                    Box 1
                    <span class="bank-icon h4-bold primary-60">x2</span>
                  </h4>
                  <div class="d-flex align-items-center justify-content-end spacing-8 flex-wrap">
                    <span class="h4-reg primary-60">Packed 8 items</span>
                    <span class="dot-divder"></span>
                    <span class="h4-reg primary-60">10 kg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions w-100 d-flex align-items-center flex-column spacing-4 pt-24">
            <app-buttons
              class="w-100"
              [active]="false"
              [disabled]="false"
              label="Download shipment label"
              size="large"
              Type="withoutIcon"></app-buttons>

            <app-secondary-btns
              class="w-100"
              [active]="false"
              [disabled]="false"
              label="Download shipment invoice"
              size="large"
              Type="leftIcon"
              IconValue="⌘N"
              IconType="download"></app-secondary-btns>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
