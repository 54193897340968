<div class="accordion-content">
  <div class="nested-accordion">
    <div class="payment-widget d-flex flex-column spacing-16">
      <div class="media d-flex align-items-start spacing-16">
        <div class="media-left">
          <div class="avatar">
            <span class="custom-icon">🤩</span>
          </div>
        </div>
        <div
          class="media-body d-flex flex-column align-items-start justify-content-start spacing-2 flex-1 flex-wrap">
          <h4 class="h3-bold m-0 p-0 primary-100 d-flex align-items-center spacing-4">
            Koman Godar <img src="/assets/verified.svg" />
          </h4>
          <div
            class="d-flex flex-column align-items-start justify-content-start spacing-8 flex-wrap">
            <span class="h4-reg primary-60">kaman&#64;gmail.com</span>
            <span class="h4-reg primary-60">9988052117</span>
          </div>
        </div>
      </div>
      <div class="address d-flex align-items-start spacing-16">
        <div class="shipping-address">
          <span class="h4-reg primary-60"> Shipping address </span>
          <p class="h4-reg primary-100 m-0 p-0">
            Shop No.9, Ishwari Market, Kahu Kothi, KKanpur, Uttar Pradesh PIN- 208001 Phone -
            9260988261
          </p>
        </div>
        <div class="shipping-address">
          <span class="h4-reg primary-60"> Billing address </span>
          <p class="h4-reg primary-100 m-0 p-0">
            Shop No.9, Ishwari Market, Kahu Kothi, KKanpur, Uttar Pradesh PIN- 208001 Phone -
            9260988261
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
