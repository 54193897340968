import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from '@services/core-services/api.service';
import { Query } from '@services/core-services/models';

@Injectable({ providedIn: 'root' })
export class GlobalSearchService {
  constructor(private readonly api: ApiService) {}

  getSearchData(query: Query) {
    return this.api.get<any>(`store/search`, query).pipe(
      map((response: any) => {
        return response.content;
      })
    );
  }
}
