import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayLayoutComponent } from '@enginuity/core/layouts/overlay-layout/overlay-layout.component';
import { OverlayTopWrapperComponent } from '@enginuity/core/organisms/overlay-top-wrapper/overlay-top-wrapper.component';
import { OverlayValueDataComponent } from '@enginuity/core/organisms/overlay-value-data/overlay-value-data.component';
import { BoxTabComponent } from '@enginuity/core/molecules/box-tab/box-tab.component';
import { OverlaySummaryComponent } from '@enginuity/core/organisms/overlay-summary/overlay-summary.component';
import { TableDataComponent } from '@enginuity/core/organisms/table-data/table-data.component';
import { DetailsComponent } from '../details/details.component';
import { ActivityTabComponent } from '../activity-tab/activity-tab.component';
import { OrderTabComponent } from '../order-tab/order-tab.component';
import { ModalComponent } from '@enginuity/core/organisms/modal/modal.component';
import { ActionEvent } from '@services/ui-services/models/action-event';
import { MatDialogModule, MatDialog, type MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss'],
  imports: [
    CommonModule,
    OverlayLayoutComponent,
    OverlayTopWrapperComponent,
    OverlayValueDataComponent,
    BoxTabComponent,
    OverlaySummaryComponent,
    TableDataComponent,
    DetailsComponent,
    ActivityTabComponent,
    OrderTabComponent,
    ModalComponent,
    MatDialogModule,
  ],
})
export class SubscriptionDetailsComponent {
  @ViewChild('stop', { static: true })
  stopCampaignTempRef!: TemplateRef<any>;
  @ViewChild('pause', { static: true })
  pauseCampaignTempRef!: TemplateRef<any>;
  @ViewChild('skip', { static: true })
  skipDeliveryTempRef!: TemplateRef<any>;
  @ViewChild('frequency', { static: true })
  bfrequencyTempRef!: TemplateRef<any>;
  @ViewChild('billing', { static: true })
  scheduleTempRef!: TemplateRef<any>;

  private dialogRef: MatDialogRef<any> | undefined;

  constructor(private dialog: MatDialog) {}
  @Input()
  action: boolean = false;

  @Input()
  modalDetail: any = {
    modalHeading: 'Stop subscription',
    modalDescription: 'You are about to stop this campaign',
    topIcon: 'warning',
    showContent: false,
    showIcon: true,
    showBtns: true,
    search: false,
    primaryBtn: {
      label: 'Stop subscription',
      active: false,
      alert: true,
      disabled: false,
      continueBtn: true,
    },
    secondaryBtn: {
      label: 'Cancel',
      backBtn: true,
    },
  };

  @Input()
  billingFrequency: any = {
    modalHeading: 'Change billing frequency',
    modalDescription:
      'You are about to change billing frequency. Save the file as a draft to not lose your progress.',
    topIcon: 'warning',
    showContent: true,
    showInput: true,
    showIcon: true,
    showBtns: true,
    search: false,
    input: [
      {
        label: 'Frequency',
        name: 'name',
        form: 'form',
        active: false,
        placeholder: 'Monthly',
        dropdown: true,
        dropdownItems: [
          {
            value: 'weekly',
            label: 'Weekly',
          },
          {
            value: 'monthly',
            label: 'Monthly',
          },
          {
            value: 'annual',
            label: 'Annual',
          },
        ],
      },
    ],
    primaryBtn: {
      label: 'Change billing frequency',
      active: false,
      alert: false,
      disabled: false,
      continueBtn: true,
    },
    secondaryBtn: {
      label: 'Cancel',
      backBtn: true,
    },
  };

  @Input()
  schedule: any = {
    modalHeading: 'Change billing schedule',
    modalDescription:
      'You are about to change billing schedule. Save the file as a draft to not lose your progress.',
    topIcon: 'warning',
    calendarLabel: 'Current schedule',
    showContent: true,
    showCalander: true,
    showInput: false,
    showIcon: true,
    showBtns: true,
    search: false,
    primaryBtn: {
      label: 'Change billing schedule',
      active: false,
      alert: false,
      disabled: false,
      continueBtn: true,
    },
    secondaryBtn: {
      label: 'Cancel',
      backBtn: true,
    },
  };

  @Input()
  pauseCampaign: any = {
    modalHeading: 'Pause subscription',
    modalDescription: 'You are about to pause this subscription',
    topIcon: 'warning',
    showContent: false,
    showIcon: true,
    showBtns: true,
    search: false,
    primaryBtn: {
      label: 'Pause',
      active: false,
      alert: false,
      disabled: false,
      continueBtn: true,
    },
    secondaryBtn: {
      label: 'Cancel',
      backBtn: true,
    },
  };

  @Input()
  skipDelivery: any = {
    modalHeading: 'You are about to skip this delivery',
    modalDescription:
      'You are about to be directed to the coupons section. Save the file as a draft to not lose your progress.',
    topIcon: 'warning',
    alertContent: 'Next delivery: In 3 days',
    alertBar: true,
    showContent: true,
    showIcon: true,
    showBtns: true,
    search: false,
    primaryBtn: {
      label: 'Skip delivery',
      active: false,
      alert: false,
      disabled: false,
      continueBtn: true,
    },
    secondaryBtn: {
      label: 'Cancel',
      backBtn: true,
    },
  };

  @Input()
  orderTab: any[] = [
    {
      label: 'Details',
      id: 'detailTab',
      icon: 'info',
      active: true,
    },
    {
      label: 'Orders',
      id: 'ordersTab',
      icon: 'cart',
      active: false,
    },
    {
      label: 'Activity',
      id: 'activityTab',
      icon: 'subscription',
      active: false,
    },
  ];

  @Input()
  dropdown: any = [
    {
      name: 'Skip next delivery',
      icon: 'subscription',
      color: 'default',
      checkbox: false,
      radio: false,
      toggle: false,
      showIcon: true,
    },
    {
      name: 'Change schedule',
      icon: 'balance',
      color: 'default',
      checkbox: false,
      radio: false,
      toggle: false,
      showIcon: true,
    },
    {
      name: 'Change billing frequency',
      icon: 'list',
      color: 'default',
      checkbox: false,
      radio: false,
      toggle: false,
      showIcon: true,
    },
    {
      name: 'Pause subscription',
      icon: 'jam_hashtag',
      color: 'default',
      checkbox: false,
      radio: false,
      toggle: false,
      showIcon: true,
    },
    {
      name: 'Stop subscription',
      icon: 'product',
      color: 'red',
      checkbox: false,
      radio: false,
      toggle: false,
      showIcon: true,
    },
  ];

  @Input()
  orderStatusOptions: any = [
    {
      label: 'pending',
      Type: 'draft',
      withIcon: true,
      IconType: 'dot',
    },
    {
      label: 'awaiting_approval',
      Type: 'draft',
      withIcon: true,
      IconType: 'dot',
    },
    {
      label: 'complete',
      Type: 'completed',
      withIcon: true,
      IconType: 'dot',
    },
    {
      label: 'cancelled',
      Type: 'cancelled',
      withIcon: true,
      IconType: 'dot',
    },
  ];
  orderStatusValue: any = {
    label: 'Delivered',
    Type: 'completed',
    withIcon: true,
    IconType: 'dot',
  };
  @Input()
  dropdown_panel = [
    {
      icon: 'bank',
      count: '',
      title: 'Customer Details',
    },
    {
      icon: 'balance',
      count: '',
      title: 'Payments',
    },
    {
      icon: 'truck',
      count: '',
      title: 'Shipments',
    },
  ];

  onAction(event: ActionEvent) {
    const key = event.key || event.label;
    if (key.toLowerCase() == 'stop subscription') {
      this.openStopCampaignModal();
    }
    if (key.toLowerCase() == 'pause subscription') {
      this.openCampaignModal();
    }
    if (key.toLowerCase() == 'skip next delivery') {
      this.openSkipModal();
    }
    if (key.toLowerCase() == 'change billing frequency') {
      this.openBillingModal();
    }
    if (key.toLowerCase() == 'change schedule') {
      this.openScheduleModal();
    }
  }

  onTabSelected(tabId: string): void {
    this.orderTab.forEach(tab => (tab.active = tab.id === tabId));
  }

  openBillingModal() {
    this.dialogRef = this.dialog.open(this.bfrequencyTempRef, {
      data: this.billingFrequency,
      disableClose: true, // Prevent closing the modal by clicking outside
    });

    // Handle the dialog close event
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        // this.stop('confirm');
      } else {
        this.closeDeleteModal();
      }
    });
  }
  openSkipModal() {
    this.dialogRef = this.dialog.open(this.skipDeliveryTempRef, {
      data: this.skipDelivery,
      disableClose: true, // Prevent closing the modal by clicking outside
    });

    // Handle the dialog close event
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        // this.stop('confirm');
      } else {
        this.closeDeleteModal();
      }
    });
  }
  openScheduleModal() {
    this.dialogRef = this.dialog.open(this.scheduleTempRef, {
      data: this.schedule,
      disableClose: true, // Prevent closing the modal by clicking outside
    });

    // Handle the dialog close event
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        // this.stop('confirm');
      } else {
        this.closeDeleteModal();
      }
    });
  }
  openStopCampaignModal() {
    this.dialogRef = this.dialog.open(this.stopCampaignTempRef, {
      data: this.modalDetail,
      disableClose: true, // Prevent closing the modal by clicking outside
    });

    // Handle the dialog close event
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        // this.stop('confirm');
      } else {
        this.closeDeleteModal();
      }
    });
  }
  openCampaignModal() {
    this.dialogRef = this.dialog.open(this.pauseCampaignTempRef, {
      data: this.pauseCampaign,
      disableClose: true, // Prevent closing the modal by clicking outside
    });

    // Handle the dialog close event
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        // this.stop('confirm');
      } else {
        this.closeDeleteModal();
      }
    });
  }
  closeDeleteModal() {
    if (this.dialogRef) {
      this.dialogRef.close(); // Close the dialog
    }
  }
  closeModal() {
    this.closeDeleteModal();
  }
}
