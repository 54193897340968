import { TopProductList } from '../constants/top-product-table-header';
import { OrderProductStats } from '@services/analytics-services/orders/models/order-product';

type TopProductTableRow = OrderProductStats & {
  isSelected?: boolean;
};

export const mapTopProductTableData = (products: TopProductTableRow) => {
  let index = 0;
  return [
    {
      column: TopProductList[index++].column,
      type: 'product_details',
      product_details: {
        product_img: products.product_image_url,
        product_name: products.product_name,
      },
    },
    {
      column: TopProductList[index++].column,
      type: 'text',
      text: {
        title: products?.quantity,
      },
    },
    {
      column: TopProductList[index++].column,
      type: 'text',
      text: {
        title: (products?.quantity * products?.average_selling_price).toFixed(2),
      },
    },
    {
      column: TopProductList[index++].column,
      type: 'text',
      text: {
        title: products?.order_count,
      },
    },
    {
      column: TopProductList[index++].column,
      type: 'text',
      text: {
        title: products?.customer_count,
      },
    },
    {
      column: TopProductList[index++].column,
      type: 'text',
      text: {
        title: products?.average_selling_price,
      },
    },
    {
      column: TopProductList[index++].column,
      type: 'actions',
      label: '',
      actions: [
        {
          icon: 'chevron_right',
          action: 'product-detail',
          text: '',
          active: false,
          productId: products.product_id,
        },
      ],
    },
  ];
};
