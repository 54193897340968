import { loyaltyActivityList } from './loyalty-activity-table-header';
import { LoyaltyPointActivity } from '../models/loyaltiy-activity';

type LoyaltyActivityTableRow = LoyaltyPointActivity & {
  isSelected?: boolean;
};

export const mapLoyaltyActivityTableData = (activities: LoyaltyActivityTableRow) => {
  let index = 0;
  return [
    {
      column: loyaltyActivityList[index++].column,
      type: 'text',
      text: {
        title: activities.title,
      },
    },
    {
      column: loyaltyActivityList[index++].column,
      type: 'text',
      text: {
        title: activities?.activity,
      },
    },
    {
      column: loyaltyActivityList[index++].column,
      type: 'text',
      text: {
        title: activities?.loyalty_point,
      },
    },
    {
      column: loyaltyActivityList[index++].column,
      type: 'text',
      text: {
        title: activities?.activity,
      },
    },
  ];
};
