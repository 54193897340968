import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { IconContainerComponent } from '@enginuity/core/atoms/icon-container/icon-container.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';

@Component({
  selector: 'app-shipment-details-panel',
  templateUrl: './shipment-details-panel.component.html',
  styleUrls: ['./shipment-details-panel.component.scss'],
  imports: [
    CommonModule,
    BadgesComponent,
    ButtonsComponent,
    SecondaryBtnsComponent,
    IconContainerComponent,
    LinkBtnComponent,
  ],
})
export class ShipmentDetailsPanelComponent {}
