import { Component, Input } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';

@Component({
  selector: 'app-overlay-summary',
  templateUrl: './overlay-summary.component.html',
  styleUrls: ['./overlay-summary.component.scss'],
  imports: [CommonModule, BadgesComponent, CurrencyPipe],
})
export class OverlaySummaryComponent {
  @Input()
  show_b2b_price = true;

  @Input()
  summary: any = {};
}
