<div class="accordion-content w-100 d-flex flex-column spacing-16">
  <app-blocks
    data-bs-toggle="collapse"
    href="#payment_detail"
    role="button"
    aria-expanded="false"
    aria-controls="payment_detail"
    [order]="'₹ 19,404'"
    [view_detail]="false"
    [date]="'11 Nov 2022'"
    [container_icon]="'bank'"
    [store_icon]="'amazon'"
    [store]="false"
    [price]="false"
    [status]="true"
    [status_label]="'Done'"
    [status_icon]="''"
    [state]="'without-container'"></app-blocks>

  <div class="collapse" id="payment_detail">
    <div class="collapse subTotal-sec d-flex flex-wrap flex-column spacing-12 w-100">
      <div class="border-line"></div>
      <div class="d-flex align-items-center justify-content-between">
        <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Transaction ID:</span>
        <app-badges
          class="cursor-pointer"
          [withIcon]="false"
          Type="white"
          IconType="dot"
          [label]="label"
          cdkCopyToClipboard="{{ label }}"
          (click)="copy(label)"
          (click)="tooltip.show()"
          #tooltip="matTooltip"
          (mouseenter)="$event.stopImmediatePropagation()"
          (mouseleave)="$event.stopImmediatePropagation()"
          [matTooltip]="'Copied to clipboard'"
          [matTooltipPosition]="'above'"></app-badges>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Request ID:</span>
        <app-badges
          class="cursor-pointer"
          [withIcon]="false"
          Type="white"
          IconType="dot"
          [label]="label"
          cdkCopyToClipboard="{{ label }}"
          (click)="copy(label)"
          (click)="tooltip2.show()"
          #tooltip2="matTooltip"
          (mouseenter)="$event.stopImmediatePropagation()"
          (mouseleave)="$event.stopImmediatePropagation()"
          [matTooltip]="'Copied to clipboard'"
          [matTooltipPosition]="'above'"></app-badges>
      </div>
    </div>
  </div>
</div>
<div class="accordion-content w-100 d-flex flex-column spacing-16">
  <app-blocks
    data-bs-toggle="collapse"
    href="#payment_detail2"
    role="button"
    aria-expanded="false"
    aria-controls="payment_detail"
    [order]="'₹ 19,404'"
    [view_detail]="false"
    [date]="'11 Nov 2022'"
    [container_icon]="'bank'"
    [store_icon]="'amazon'"
    [store]="false"
    [price]="false"
    [status]="true"
    [status_label]="'Done'"
    [status_icon]="''"
    [state]="'without-container'"></app-blocks>

  <div class="collapse" id="payment_detail2">
    <div class="collapse subTotal-sec d-flex flex-wrap flex-column spacing-12 w-100">
      <div class="border-line"></div>
      <div class="d-flex align-items-center justify-content-between">
        <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Transaction ID:</span>
        <app-badges
          class="cursor-pointer"
          [withIcon]="false"
          Type="white"
          IconType="dot"
          [label]="label"
          cdkCopyToClipboard="{{ label }}"
          (click)="copy(label)"
          (click)="tooltip3.show()"
          #tooltip3="matTooltip"
          (mouseenter)="$event.stopImmediatePropagation()"
          (mouseleave)="$event.stopImmediatePropagation()"
          [matTooltip]="'Copied to clipboard'"
          [matTooltipPosition]="'above'"></app-badges>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <span class="h4-reg primary-60 d-flex align-items-center spacing-8">Request ID:</span>
        <app-badges
          class="cursor-pointer"
          [withIcon]="false"
          Type="white"
          IconType="dot"
          [label]="label"
          cdkCopyToClipboard="{{ label }}"
          (click)="copy(label)"
          (click)="tooltip4.show()"
          #tooltip4="matTooltip"
          (mouseenter)="$event.stopImmediatePropagation()"
          (mouseleave)="$event.stopImmediatePropagation()"
          [matTooltip]="'Copied to clipboard'"
          [matTooltipPosition]="'above'"></app-badges>
      </div>
    </div>
  </div>
</div>
