import {
  Component,
  ChangeDetectionStrategy,
  inject,
  HostListener,
  Input,
  ViewChild,
  OnInit,
} from '@angular/core';

import { Router, RouterModule } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { BoxTabComponent } from '@enginuity/core/molecules/box-tab/box-tab.component';
import { AuthService } from '@services/auth-services/auth.service';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { ProfileDropdownComponent } from '../profile-dropdown/profile-dropdown.component';
import { ActiveSearchComponent } from '../active-search/active-search.component';
import { ProfileComponent } from '../profile/profile.component';
import { CommonModule } from '@angular/common';

import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { AvatarComponent } from '@enginuity/core/molecules/avatar/avatar.component';
import { MessagesComponent } from '../messages/messages.component';
import { ApplicationMode, AppService, ThemeMode } from '@services/core-services/app.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlatformService } from '@services/core-services/platform.service';
import { UrlService } from '@services/auth-services/url.service';
import { ProtectionService } from '@services/core-services/protection.service';
import { Store } from '@services/setting-services/models';
import { StoresService } from 'src/app/setting-module/organization-settings/stores/store.service';
import { NotifyService } from '@services/core-services/notify.service';
import { User } from '@services/core-services/models';
import { InfoComponent } from '@enginuity/core/organisms/info/info.component';
import { GlobalLoaderService, LoaderState } from '@services/core-services/global-loader.service';
import { MenuSettings } from '@services/setting-services/models/menu-settings.enum';
import { OrganizationUsersService } from '@services/user-services/organization-users.service';
import { CdkMenuTrigger, CdkMenu, CdkMenuItem } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { GlobalSearchService } from '@services/core-services/global-search.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  imports: [
    CommonModule,
    BoxTabComponent,
    BreadcrumbComponent,
    NotificationsComponent,
    ProfileDropdownComponent,
    ProfileComponent,
    BadgesComponent,
    RouterModule,
    CeIconComponent,
    AvatarComponent,
    MessagesComponent,
    InfoComponent,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    OverlayModule,
    MatDialogModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavComponent implements OnInit {
  readonly dialog = inject(MatDialog);
  isMac: boolean = false;
  openSearch() {
    console.log();
    const already_dialog = this.dialog.getDialogById('active-search');
    if (!already_dialog) {
      const dialogRef = this.dialog.open(ActiveSearchComponent, {
        id: 'active-search',
        position: {
          top: '100px', // Position from the top
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }
  }

  @ViewChild('searches') searchElement: any;

  searchResults: any[] = [];
  currentUser: User | undefined;
  protected store: Store | undefined;
  protected stores: Store[] = [];
  protected message: string = 'you are in currently sandbox envirement';
  settings = MenuSettings;
  @Input()
  topNavSetting: MenuSettings.OrganisationSetting | MenuSettings.StoreSetting =
    MenuSettings.OrganisationSetting;
  isUser = false;
  isOpen = false;
  isNotification = false;

  @Input()
  notificationsCount = '2';

  onStatus() {
    this.isOpen = !this.isOpen;
  }
  user() {
    this.isUser = !this.isUser;
  }
  onNotificaion() {
    this.isNotification = !this.isNotification;
  }
  onOutsideClick() {
    this.isOpen = false; // Close overlay when clicking outside
    this.isNotification = false; // Close overlay when clicking outside
    this.isUser = false; // Close overlay when clicking outside
  }
  @Input()
  messagesCount = '3';

  @Input()
  searchTab: any[] = [
    {
      label: 'All',
      id: 'notification-tab-pane',
      active: true,
    },
    {
      label: 'Customers',
      id: 'activities-tab-pane',
      active: false,
    },
    {
      label: 'Orders',
      id: 'activities-tab-pane',
      active: false,
      icon: '23',
    },
    {
      label: 'Products',
      id: 'activities-tab-pane',
      active: false,
      icon: '23',
    },
  ];

  @Input()
  storeNames: any[] = [];

  @Input()
  breadcrumb: any[] = [];

  @Input()
  tabContent = [
    {
      label: 'Notifications',
      id: 'notification-tab-pane',
      active: true,
    },
    {
      label: 'Activities',
      id: 'activities-tab-pane',
      active: false,
    },
  ];

  @Input()
  timeContent: any[] = [
    {
      id: ThemeMode.Light,
      active: true,
      icon: 'light',
    },
    {
      id: ThemeMode.Dark,
      active: false,
      icon: 'dark_theme',
    },
  ];

  public showSearchTab = false;
  public applicationMode: ApplicationMode = this.appService.getApplicationMode();
  public applicationModeTabs = [
    {
      label: 'Sandbox',
      id: ApplicationMode.Sandbox,
      active: this.applicationMode === ApplicationMode.Sandbox,
    },
    {
      label: 'Live Mode',
      id: ApplicationMode.Live,
      active: this.applicationMode === ApplicationMode.Live,
    },
  ];

  constructor(
    private readonly authService: AuthService,
    private readonly appService: AppService,
    private readonly router: Router,
    private readonly urlService: UrlService,
    private readonly platformService: PlatformService,
    private readonly storeService: StoresService,
    private readonly protectionService: ProtectionService,
    private readonly notify: NotifyService,
    private readonly loaderService: GlobalLoaderService,
    private readonly usersService: OrganizationUsersService,
    private readonly globalSearch: GlobalSearchService
  ) {
    this.appService.applicationMode$.pipe(takeUntilDestroyed()).subscribe(mode => {
      const different = this.applicationMode !== mode;
      this.applicationMode = mode;
      if (different) {
        const url = this.urlService.replaceAppModeInBaseUrl(this.router.url, mode);
        this.router.navigateByUrl(url);
      }
    });

    this.appService.theme$.pipe(takeUntilDestroyed()).subscribe(mode => {
      this.updateSelectedThemeMode(mode);
    });
    const tenant = this.protectionService.getTenant();
    this.storeService.getStores(tenant!.id).subscribe(stores => this.mapStores(stores));
    this.store = this.protectionService.getCurrentStoreSnapshot();
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const isCtrlPressed = event.ctrlKey || event.metaKey;
    if (isCtrlPressed && event.key.toLowerCase() === 'k') {
      // this.dialog.closeAll()
      event.preventDefault();
      this.openSearch();
    }
    if (event.key === '/') {
      if (!['input', 'textarea'].includes((event.target as Element).tagName.toLowerCase())) {
        event.preventDefault();
        this.searchElement.nativeElement.classList.add('showsearchbar');
        this.searchElement.nativeElement.querySelector('input').focus();
      }
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      // this.searchElement.nativeElement.querySelector('input').blur();
      // this.searchElement.nativeElement.classList.remove('showsearchbar');
    }
  }
  onSearch(event: any) {
    const query: any = {
      search: event,
    };

    this.globalSearch.getSearchData(query).subscribe({
      next: results => {
        this.searchResults = results.results;
        this.showSearchTab = true; // Show search tab on results
        this.searchElement.nativeElement.classList.add('showsearchbar');
        // this.searchElement.nativeElement.querySelector('input');
      },
      error: err => {
        console.error('Error fetching search results:', err);
      },
    });
  }
  onProfileAction(event: any) {
    if (event == 'logout') {
      this.authService.logout().then(res => {
        this.router.navigate(['auth', 'login']);
      });
    }
    if (event == 'report-bug') {
      const eventId = Sentry.captureMessage('User initiated report');
      Sentry.showReportDialog({ eventId });
    }
  }

  selectMode(mode: string) {
    this.applicationModeTabs.forEach((item: any) => {
      item.active = item.id === mode;
    });
    this.updateLoaderMessages(this.applicationMode);
    this.appService.setApplicationMode(mode as ApplicationMode);
  }

  private updateSelectedThemeMode(mode: ThemeMode) {
    this.timeContent = this.timeContent.map((t: any) => ({ ...t, active: t.id === mode }));
  }

  private mapStores(stores: Store[]) {
    this.storeNames = stores.map(store => {
      const abbr = store.name.substring(0, 1);
      const isActive = this.store ? this.store.name === store.name : store.is_default;
      return {
        slug: store.slug,
        name: store.name,
        icon: abbr,
        selectable: true,
        active: isActive,
      };
    });
  }

  handleStoreSelect(store: any) {
    const { host, protocol } = this.urlService.getUrlSettings();
    this.platformService.redirect(`${protocol}//${host}/${store.slug}`);
    this.notify.success(`Switched to store: ${store.name}`);
  }

  ngOnInit() {
    this.usersService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
    });
    this.currentUser = this.authService.getCurrentUser();
    if (this.applicationMode === ApplicationMode.Sandbox) {
      this.message = 'You are currently in sandbox environment';
      document.body.classList.add('mode');
    } else {
      document.body.classList.remove('mode');
    }
  }

  private updateLoaderMessages(mode: ApplicationMode) {
    if (mode === ApplicationMode.Live) {
      this.loaderService.setContent({
        title: '🔧 Switching to Sandbox Mode…',
        message:
          'Hold tight! We’re setting up your sandbox for safe testing. You’ll be back in the testing environment in no time!',
      });
    } else if (mode === ApplicationMode.Sandbox) {
      this.loaderService.setContent({
        title: '🚀 Switching to Live Mode…',
        message:
          'Get ready! We’re powering up for live operations. This will just take a moment as we prepare everything for real-time action!',
      });
    }

    this.loaderService.setStatus(LoaderState.InProgress);
    setTimeout(() => {
      this.loaderService.setStatus(LoaderState.None); // Hide loader after a delay (you can adjust the delay)
    }, 1000);
  }
  displayUserInfo(): any {
    if (this.currentUser) {
      return {
        name: (this.currentUser.first_name || '') + ' ' + (this.currentUser.last_name || '').trim(),
        email: this.currentUser.email,
        photo: this.currentUser.image_url,
      };
    }
    return {
      name: 'Guest',
      email: '',
    };
  }
}
