<!-- <div class="tab-header">
  <ul
    [class.full-width]="fullWidth"
    class="nav nav-tabs nav-fill"
    id="myTab"
    role="tablist"
    aria-busy="true">
    @for (tab of visibleTabs; track tab) {
      <li class="nav-item" (click)="tabClicked(tab)" role="presentation">
        <button
          class="nav-link h3-reg"
          [ngClass]="{ active: tab.active }"
          data-bs-toggle="tab"
          [attr.data-bs-target]="'#' + tab.id"
          type="button"
          role="tab"
          aria-controls=""
          aria-selected="true"
          (click)="selectedTab.emit(tab); tabClickedValue = undefined">
          {{ tab.label }}
          @if (tab.icon) {
            <span class="h4-case tab-icon count-{{ Type }}" [ngClass]="{ 'tab-count': withIcon }">{{
              tab.icon
            }}</span>
          }
          @if (tab.removable) {
            <app-action-btn
              IconType="delete"
              [active]="false"
              (custom_click)="handleRemove($event, tab)"></app-action-btn>
          }
        </button>
      </li>
    }
    @if (tabClickedValue) {
      <li class="nav-item">
        <button
          class="nav-link h3-reg"
          [ngClass]="{ active: tabClickedValue?.active }"
          data-bs-toggle="tab"
          type="button"
          role="tab"
          aria-controls=""
          aria-selected="true">
          {{ tabClickedValue?.label }}
        </button>
      </li>
    }

    @if (moreTabsList.length > 0) {
      <li class="nav-item dropdown" aria-busy="true">
        <app-link-btn
          [label]="'+' + moreTabsList.length + ' More'"
          [active]="false"
          [alert]="false"
          [size]="'large'"
          [IconType]="'chevron_down'"
          (custom_click)="moreTabs()"
          [cdkMenuTriggerFor]="menu"
          cdkConnectedOverlayOrigin></app-link-btn>
      </li>
      <ng-template #menu>
        <div class="bottom-margin" cdkMenu>
          <div class="custom-dropdown customer-dropdown radius-4 radius-top-left">
            @for (tab of moreTabsList; track tab; let i = $index) {
              <dropdown-single-piece
                class="w-100 cursor-pointer"
                (click)="selectMoreTab(tab)"
                [label]="tab.label"
                [color]="'default'"
                [type]="'bold'"
                [state]="'default'"
                [showUser]="false"
                [icon]="false"
                [iconType]="'balance'"
                [dragDrop]="false"
                [toggle]="false"
                [more]="false"
                [radio]="false"
                [checkbox]="false"
                cdkMenuItem></dropdown-single-piece>
            }
          </div>
        </div>
      </ng-template>
    }
    <ul class="p-0 m-0" role="list">
      @if (saveAsView) {
        <li class="nav-item border-left d-block" aria-busy="true">
          <app-link-btn
            class="nav-link"
            (click)="onClick.emit('save')"
            label="Save as a view"
            class="nav-link"
            [active]="false"
            size="large"
            IconType="save"
            [disabled]="false"></app-link-btn>
        </li>
      }
    </ul>
  </ul>
</div> -->

<div class="tab-header table-tab-header">
  <mat-tab-group
    [dynamicHeight]="true"
    class="nav-tabs nav-fill"
    [selectedIndex]="getActiveTabIndex()"
    (selectedIndexChange)="onTabChange($event)">
    <mat-tab
      *ngFor="let tab of visibleTabs; trackBy: trackByTab"
      [label]="tab.label"
      class="nav-item">
      <ng-template mat-tab-label>
        <span>{{ tab.label }}</span>
        <ng-container *ngIf="tab.icon">
          <span class="h4-case tab-icon count-{{ Type }}" [ngClass]="{ 'tab-count': withIcon }">
            {{ tab.icon }}
          </span>
        </ng-container>
        <ng-container *ngIf="tab.removable">
          <app-action-btn
            IconType="delete"
            [active]="false"
            (custom_click)="handleRemove($event, tab)"></app-action-btn>
        </ng-container>
      </ng-template>
    </mat-tab>

    <!-- More Tabs Dropdown -->
    <mat-tab *ngIf="moreTabsList.length > 0" class="nav-item dropdown">
      <ng-template mat-tab-label>
        <app-link-btn
          [label]="'+' + moreTabsList.length + ' More'"
          [active]="false"
          [alert]="false"
          [size]="'large'"
          [IconType]="'chevron_down'"
          (custom_click)="moreTabs()"></app-link-btn>
      </ng-template>
      <ng-template mat-tab-content>
        <div class="bottom-margin">
          <div class="custom-dropdown customer-dropdown radius-4 radius-top-left">
            <dropdown-single-piece
              *ngFor="let tab of moreTabsList; trackBy: trackByTab"
              class="w-100 cursor-pointer"
              (click)="selectMoreTab(tab)"
              [label]="tab.label"
              [color]="'default'"
              [type]="'bold'"
              [state]="'default'"
              [showUser]="false"
              [icon]="false"
              [iconType]="'balance'"
              [dragDrop]="false"
              [toggle]="false"
              [more]="false"
              [radio]="false"
              [checkbox]="false"></dropdown-single-piece>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <ul class="p-0 m-0" role="list">
    <li *ngIf="saveAsView" class="nav-item border-left d-block">
      <app-link-btn
        class="nav-link"
        (click)="onClick.emit('save')"
        label="Save as a view"
        [active]="false"
        size="large"
        IconType="save"
        [disabled]="false"></app-link-btn>
    </li>
  </ul>
</div>
