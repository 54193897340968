import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AnalyticsBlockComponent } from '@enginuity/core/organisms/analytics-block/analytics-block.component';
import { TableFilterComponent } from '@enginuity/core/organisms/table-filter/table-filter.component';
import { TableViewComponent } from '@enginuity/core/organisms/table-view/table-view.component';
import { TableFooterComponent } from '@enginuity/core/organisms/table-footer/table-footer.component';
import { LayoutService } from '@services/ui-services/layout.service';
import { SubscriptionDetailsComponent } from '@enginuity/customer/sections/overlays/Subscription/subscription-details/subscription-details.component';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@services/core-services/data.service';
import { AppSettings } from '@services/core-services/app.settings';
import { FooterInformation } from '@services/ui-services/models/footer-information';
import { TableHelperService } from '@services/ui-services/table-helper.service';
import {
  FOOTER_LINKS,
  SHIPPING_TAB,
  SUBSCRIPTION_TABLE,
} from '@services/customer-services/constants/subscription-tab.constant';
import { ModalService } from '@services/core-services/modal.service';

@Component({
  selector: 'app-subscriptions-tab',
  templateUrl: './subscriptions-tab.component.html',
  styleUrls: ['./subscriptions-tab.component.scss'],
  imports: [
    CommonModule,
    AnalyticsBlockComponent,
    TableFilterComponent,
    TableViewComponent,
    TableFooterComponent,
    SubscriptionDetailsComponent,
  ],
})
export class SubscriptionsTabComponent implements OnInit {
  openOverlay = false;
  changefrequency = false;
  changeschedule = false;
  activeIds: string[] = [];
  itemsPerPage = 5;

  footerData: FooterInformation = {
    isShowPagination: true,
    currentPage: 1,
    totalPages: 1,
    totalRows: 6,
    isShowSelected: true,
    selectedRows: 0,
    showingRowsCount: 6,
  };

  customer_id: number = 0;

  shippingTab = SHIPPING_TAB;
  footerLinks = FOOTER_LINKS;
  table_schema = SUBSCRIPTION_TABLE;
  isCheckAll: boolean = false;

  filterSearch: any = { searchData: '' };
  sortList: any = {};

  constructor(
    private layoutService: LayoutService,
    private active_route: ActivatedRoute,
    private dataService: DataService,
    private appSettings: AppSettings,
    private tableHelperService: TableHelperService,
    private readonly modalService: ModalService
  ) {}

  openVerticallyCentered(content: any) {
    this.modalService.open(content);
  }

  // ngOnInit(): void {
  //   this.activeIds = ["upcoming-order", "past-order"];
  // }

  ngOnInit(): void {
    this.customer_id = Number(this.active_route.parent!.snapshot.paramMap.get('id'));
    //this.getCustomerSubscriptionData();

    this.layoutService.toggle_layout_overlay$.subscribe((res: boolean) => {
      this.openOverlay = res;
      if (res) {
        document.body.classList.add('hidden');
      } else {
        document.body.classList.remove('hidden');
      }
    });
  }

  openModel(event: any) {
    this.openOverlay = true;
  }

  subscriptionoverlayTab($event: any) {
    this.shippingTab = $event;
  }

  getCustomerSubscriptionData(
    pageNumber: number = 1,
    filterSearch = this.filterSearch,
    sortList = this.sortList
  ) {
    this.filterSearch = filterSearch;
    this.sortList = sortList;
    this.dataService
      .get(`${this.appSettings.app_config.base_url}subscriptions`, {
        page: pageNumber,
        per_page_items: this.itemsPerPage,
        business_id: this.customer_id,
        search: filterSearch.searchData || '',
        sortList: JSON.stringify(sortList),
        filters: JSON.stringify(filterSearch.filters),
      })
      .subscribe(res => {
        this.table_schema.data = res.content.orders;

        this.tableHelperService.setFooter(this.footerData, res, this.table_schema.data);
      });
  }

  tableDataClick(event: any) {
    if (event.action === 'open_overlay') {
      this.openModel(event);
    } else if (event.action === 'header-checkbox') {
      this.onHeaderCheckChange();
    } else if (event.action === 'row-checkbox') {
      this.onRowCheckChange(event.value.data);
    }
  }
  setTableRows(customers: any) {
    const rows: any = [];
    for (const data of customers) {
      rows.push(
        { type: 'checkbox', label: '' },
        {
          type: 'text',
          text: {
            title: data.type, //'Premium',
          },
        },
        {
          type: 'status',
          status: {
            label: 'Active',
            Type: data.status, //'completed',
            IconType: 'no_icon',
          },
        },
        {
          type: 'text',
          text: {
            title: data.amount, //'₹ 29,900',
          },
        },
        {
          type: 'status',
          status: {
            label: data.payment_type, //'Monthly',
            Type: 'tag',
            IconType: 'no_icon',
          },
        },
        {
          column: this.table_schema.head[5].column,
          type: 'date',
          date: {
            title: data.created_at,
            format: 'dd MMM, yyyy',
          },
        },
        {
          type: 'text',
          text: {
            title: '5 days ago',
          },
        },
        {
          type: 'actions',
          label: '',
          actions: [
            {
              icon: 'chevron_right',
              text: '',
              active: false,
              action: 'open_overlay',
            },
          ],
        }
      );
    }
    this.table_schema.rows = rows;
    return this.table_schema;
  }

  onHeaderCheckChange() {
    this.isCheckAll = !this.isCheckAll;
    this.table_schema.data.map((customer: any) => (customer.isSelected = this.isCheckAll));
    //this.setTableRows(this.table_schema.data);
    this.footerData.selectedRows = this.selectedRows;
    this.tableHelperService.resetHeader(this.table_schema);
  }

  onRowCheckChange(data: any) {
    const customer = this.table_schema.data.find((x: any) => x.id === data.id);
    customer.isSelected = !customer.isSelected;

    //this.setTableRows(this.table_schema.data);
    this.footerData.selectedRows = this.selectedRows;
    this.tableHelperService.resetHeader(this.table_schema);
  }

  get selectedRows() {
    return this.table_schema.data.filter((customer: any) => customer.isSelected === true).length;
  }

  onSortColumn(sortList: any) {
    this.getCustomerSubscriptionData(1, this.filterSearch, sortList);
  }
}
