<div class="detail-section sidebarScroll-height custom-scroll p-0 spacing-24">
  <div class="d-flex align-items-center flex-column spacing-8">
    <div
      class="grid-heading activityHeading d-flex align-items-center justify-content-between w-100">
      <h4 class="h4-reg m-0 primary-60">15 September 2022</h4>
    </div>
    <div class="activities w-100">
      <ul>
        <li>
          <div class="media d-flex align-items-start spacing-16">
            <app-avatar [Type]="'text'" [short_name]="'M'"></app-avatar>
            <div
              class="media-body d-flex flex-column align-items-start justify-content-start spacing-8 flex-1">
              <h4 class="h3-bold m-0 p-0 primary-100">
                Warner Goes <span class="h3-reg">left a comment</span>
              </h4>
              <div class="time d-flex">
                <span class="h4-reg primary-70">2:43 AM</span>
              </div>
              <div
                class="notification-msg d-flex justify-content-start p-16 h4-reg text-black border-radius border-30">
                “Oh, I finished de-bugging the phones, but the system's compiling for eighteen
                minutes, or twenty. So, some minor systems may go on and off for a while.”
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="media d-flex align-items-center spacing-16">
            <app-icon-container
              [Type]="'icon'"
              [Size]="'large'"
              [IconType]="'cart'"
              [background_color]="'success-100'"></app-icon-container>
            <div
              class="media-body d-flex flex-column align-items-start justify-content-start spacing-8 flex-1">
              <h4 class="h3-bold m-0 p-0 primary-100">Product added to a basket</h4>
            </div>
            <span class="h4-reg primary-70">3:00 AM</span>
          </div>
        </li>

        <li>
          <div class="media d-flex align-items-start spacing-16">
            <app-icon-container
              [Type]="'icon'"
              [Size]="'large'"
              [IconType]="'cart'"
              [background_color]="'primary-20'"></app-icon-container>
            <div
              class="media-body d-flex flex-column align-items-start justify-content-start spacing-8 flex-1">
              <div class="d-flex align-items-center justify-content-between w-100">
                <h4 class="h3-bold m-0 p-0 primary-100">Product Viewed</h4>
                <span class="h4-reg primary-70">3:00 AM</span>
              </div>
              <div class="d-flex align-items-center justify-content-between w-100 order-block p-12">
                <div class="media d-flex align-items-center spacing-16">
                  <div class="media-left">
                    <span class="product-icon"><img alt="" src="/assets/product.jpg" /></span>
                  </div>
                  <div
                    class="media-body d-flex flex-column align-items-start justify-content-start spacing-2">
                    <h4 class="h4-bold m-0 p-0 primary-100 d-flex align-items-center spacing-4">
                      PISA l 4.5gm l Injection
                    </h4>
                    <div class="d-flex align-items-center justify-content-start spacing-8">
                      <span class="h4-reg primary-60">₹ 7,100</span>
                      <span class="dot-divder"></span>
                      <span class="h4-reg primary-60">Red</span>
                    </div>
                  </div>
                </div>
                <app-secondary-btns
                  [active]="false"
                  [label]="'Go to product'"
                  [size]="'small'"
                  [Type]="'withoutIcon'"
                  [IconValue]="''"></app-secondary-btns>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="leave-comment">
  <textarea colspan="7" class="form-control" placeholder="Leave a comment..."></textarea>
  <button type="submit" class="circle-btn">
    <app-ce-icon [name]="'arrow_export'" [svg_class]="['primary-50']"></app-ce-icon>
  </button>
</div>
