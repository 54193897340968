import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { CheckboxComponent } from '@enginuity/core/molecules/checkbox/checkbox.component';
import { SearchComponent } from '@enginuity/core/molecules/search/search.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';

import { AnalyticsBlockComponent } from '@enginuity/core/organisms/analytics-block/analytics-block.component';
import { TableFilterComponent } from '@enginuity/core/organisms/table-filter/table-filter.component';
import { TableViewComponent } from '@enginuity/core/organisms/table-view/table-view.component';
import { TableFooterComponent } from '@enginuity/core/organisms/table-footer/table-footer.component';
import { LoyaltyPointsComponent } from '@enginuity/customer/sections/loyalty-points/loyalty-points.component';
import { ListFooterComponent } from '@enginuity/core/organisms/list-footer/list-footer.component';
import { PaginatedList, Pagination, ProcessingState } from '@services/core-services/models';
import { Observable, tap } from 'rxjs';
import { CustomerDetailsStore } from '../customer-details.store';
import { TableView } from '@services/ui-services/models';
import { loyaltyActivityList } from '@services/loyalty-services/constants/loyalty-activity-table-header';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mapLoyaltyActivityTableData } from '@services/loyalty-services/constants/loyalty-activity-table.mapper';
import { LoyaltyPointActivity } from '@services/loyalty-services/models/loyaltiy-activity';

@Component({
  selector: 'app-loyalty-tab',
  templateUrl: './loyalty-tab.component.html',
  styleUrls: ['./loyalty-tab.component.scss'],
  imports: [
    CommonModule,
    AnalyticsBlockComponent,
    TableFilterComponent,
    TableViewComponent,
    ListFooterComponent,
  ],
})
export class LoyaltyTabComponent implements OnInit {
  //New changes
  protected pagination: Pagination | undefined;
  public customerLoyaltyLoader$ = new Observable<ProcessingState>();
  public errorMessage$: Observable<string>;
  protected schema: TableView = {
    head: loyaltyActivityList,
    rows: [],
    data: [],
  };

  constructor(
    private route: ActivatedRoute,
    private store: CustomerDetailsStore
  ) {
    this.errorMessage$ = this.store.errorMessage.asObservable();
    this.customerLoyaltyLoader$ = this.store.getCustomerLoyaltyLoader();
    this.store
      .getLoyaltyActivity()
      .pipe(
        takeUntilDestroyed(),
        tap(activities => this.buildLoyaltyActivityTable(activities))
      )
      .subscribe();
  }

  private buildLoyaltyActivityTable(activities: PaginatedList<LoyaltyPointActivity[]>) {
    this.pagination = activities.pagination;
    this.schema.rows = activities.list.map(activities => mapLoyaltyActivityTableData(activities));
  }

  onPageChange(selectedPage: number) {
    if (selectedPage > 0) {
      // this.getCustomerOrderData(selectedPage);
    }
  }
  ngOnInit() {
    this.store.loadCustomerLoyalty().subscribe();
  }

  onActionClick(event: any) {
    console.log(event);
    if (event.icon == 'chevron_right') {
      // this.store.openOrderDetails(event.orderId);
    }
  }

  onSortColumn(sortList: any) {
    // this.getCustomerLoyaltyData(1, this.filterSearch, sortList);
  }
}
