import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';
import { IconContainerComponent } from '@enginuity/core/atoms/icon-container/icon-container.component';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss'],
  imports: [
    CommonModule,
    BadgesComponent,
    CeIconComponent,
    ActionBtnComponent,
    IconContainerComponent,
  ],
})
export class BlocksComponent {
  @Input()
  order: any = '';

  @Input()
  view_detail = false;

  @Input()
  date = '15 Mar 2022';

  @Input() container_icon: string = '';

  @Input()
  store_icon: string = '';

  @Input()
  store = false;

  @Input()
  price = true;

  @Input()
  status = true;

  @Input()
  status_label: string = '';

  @Input()
  statusType: string = '';

  @Input()
  status_icon: string = '';

  @Input()
  amount = '29,900';

  @Input()
  tamount = '77,616';

  @Input()
  state: 'default' | 'hover' | 'without-container' = 'hover';
  $any: any;
}
