import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  imports: [CommonModule, LinkBtnComponent, RouterModule],
})
export class ProfileComponent {
  constructor(private readonly router: Router) {}
  @Input()
  title = 'your apps';

  @Input()
  action_btn = true;

  @Output() onSelect = new EventEmitter<any>();

  @Input()
  menuItems = [
    { name: 'The app name', icon: 'T', selectable: true, active: true },
    { name: 'App Name1', icon: 'T', selectable: true, active: false },
    { name: 'App Name2', icon: 'T', selectable: true, active: false },
    { name: 'App Name3', icon: 'T', selectable: true, active: false },
  ];

  createStore() {
    this.router.navigate(['onboarding', 'store', 'create']);
  }
  select(entry: any) {
    this.onSelect.emit(entry);
    for (const item of this.menuItems) {
      if (item.name == entry.name) {
        item.active = true;
      } else {
        item.active = false;
      }
    }
  }
}
