import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { BlocksComponent } from '@enginuity/core/organisms/blocks/blocks.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-payment-details-panel',
  templateUrl: './payment-details-panel.component.html',
  styleUrls: ['./payment-details-panel.component.scss'],
  imports: [CommonModule, BadgesComponent, BlocksComponent, ClipboardModule, MatTooltipModule],
})
export class PaymentDetailsPanelComponent {
  @Input()
  order = 'OD1648801525';

  @Input()
  view_detail = false;

  @Input()
  label = 'ABCD123456789';

  @Input()
  date = '15 Mar 2022';

  @Input()
  amount = '29,900';

  @Input()
  tamount = '77,616';

  copy(text: string) {}
}
