<form [formGroup]="form" class="enter-otp d-flex flex-column align-items-center spacing-40">
  <div class="d-flex flex-column align-items-center spacing-4">
    <ng-otp-input
      (onInputChange)="onOTPChange($event)"
      [config]="{ length: OtpLength }"></ng-otp-input>

    <span class="alert-140 h4-reg d-flex gap-1" *ngIf="form.get('otp')?.errors?.['invalid']">
      <app-ce-icon [name]="'alert'" [svg_class]="['d-flex']"></app-ce-icon>
      You have provided invalid OTP.
    </span>
  </div>
  <app-buttons
    class="w-100"
    [active]="false"
    [disabled]="form.invalid"
    label="Verify email and continue"
    [IconType]="processing ? 'loader' : 'import'"
    size="large"
    [Type]="processing ? 'onlyIcon' : 'withoutIcon'"
    (click)="verifyEmailWithOTP()"
    [shortcutKey]="false"></app-buttons>
  <div class="info-toast d-flex align-items-center spacing-12 justify-content-center w-100">
    <app-ce-icon [name]="'info'" [svg_class]="['d-flex', 'primary-50']"></app-ce-icon>
    <div *ngIf="timer.count === 0" class="h4-reg primary-60">Didn’t receive the OTP?</div>
    <div *ngIf="timer.count > 0" class="h4-reg primary-60">OTP sent again!</div>
    <div *ngIf="timer.count > 0 && !timer.blocked" class="h4-reg primary-60">
      Still did not receive it?
    </div>
    <div *ngIf="timer.count > 0 && timer.blocked" class="h4-reg primary-60">
      If you still have not received it, try again after
      <span class="live-count primary-100 h3-bold">{{ timer.countdown }} seconds</span>
    </div>
    @if (!timer.blocked) {
      <app-link-btn
        (click)="resendOTP()"
        label="Resend"
        [active]="false"
        size="medium"
        IconType="arrow_export"
        [disabled]="false"></app-link-btn>
    }
  </div>
</form>
