<!-- (close_overlay)="close_overlay($event) -->
<app-overlay-layout
  [action]="action"
  [added]="false"
  [overlay_footer]="false"
  [collapseTitle]="'Close the Subscription details'">
  <ng-container overlayContent>
    <app-overlay-top-wrapper
      [name]="'Subscription no. #4232'"
      [show_alert]="true"
      [showAvatar]="false"
      [show_actions]="true"
      [dropdown]="true"
      [show_primary]="true"
      date_time="1 Apr 2022, 9:31:53 AM"
      [button_label]="'Other actions'"
      [btnIcon]="''"
      [Type]="''"
      [secondary_button_label]="'View subscription'"
      [show_secondary]="false"
      [dropdownItems]="dropdown"
      (onActions)="onAction($event)"></app-overlay-top-wrapper>
    <div class="values d-flex align-items-start spacing-4 flex-column w-100">
      <app-overlay-value-data
        class="w-100"
        [title]="'Subscription status:'"
        [title_icon]="'subscription'"
        [readOnly]="false"
        [emptyskeletonLabel]="'select customer'"
        [valueType]="'status'"
        [valueOptions]="orderStatusOptions"
        [currentValue]="orderStatusValue"
        [isDropdown]="false"></app-overlay-value-data>

      <app-overlay-value-data
        class="w-100"
        [title]="'Payment status:'"
        [title_icon]="'card'"
        [readOnly]="false"
        [emptyskeletonLabel]="'select customer'"
        [valueType]="'status'"
        [valueOptions]="orderStatusOptions"
        [currentValue]="{
          label: 'Partly fulfilled',
          Type: 'paused',
          withIcon: true,
          IconType: 'dot',
        }"
        [isDropdown]="false"></app-overlay-value-data>

      <app-overlay-value-data
        class="w-100"
        [title]="'Frequency:'"
        [title_icon]="'change'"
        [readOnly]="false"
        [emptyskeletonLabel]="'select customer'"
        [valueType]="'status'"
        [valueOptions]="orderStatusOptions"
        [currentValue]="{
          label: 'Annual',
          Type: 'white',
          withIcon: false,
          IconType: 'dot',
        }"
        [isDropdown]="false"></app-overlay-value-data>

      <app-overlay-value-data
        class="w-100"
        [title]="'Fulfillment:'"
        [title_icon]="'check'"
        [readOnly]="false"
        [emptyskeletonLabel]="'select customer'"
        [valueType]="'status'"
        [valueOptions]="orderStatusOptions"
        [currentValue]="{
          label: '3 out of 12',
          Type: 'white',
          withIcon: false,
          IconType: 'dot',
        }"
        [isDropdown]="false"></app-overlay-value-data>
      <app-overlay-value-data
        class="w-100"
        [title]="'Next billing:'"
        [title_icon]="'calendar'"
        [readOnly]="false"
        [emptyskeletonLabel]="'select customer'"
        [valueType]="'status'"
        [valueOptions]="orderStatusOptions"
        [currentValue]="{
          label: '1 Apr 2023',
          Type: 'white',
          withIcon: false,
          IconType: 'dot',
        }"
        [isDropdown]="false"></app-overlay-value-data>
      <app-overlay-value-data
        class="w-100"
        [title]="'Value:'"
        [title_icon]="'balance'"
        [readOnly]="false"
        [emptyskeletonLabel]="'select customer'"
        [valueType]="'status'"
        [valueOptions]="orderStatusOptions"
        [currentValue]="{
          label: '₹ 135,828 / yr',
          Type: 'white',
          withIcon: false,
          IconType: 'dot',
        }"
        [isDropdown]="false"></app-overlay-value-data>
    </div>
    <div class="customers d-flex flex-column align-items-start spacing-8">
      <h3 class="h3-bold m-0 p-0 primary-100">Customers</h3>
      <div
        app-table-data
        [Type]="'customers'"
        [data]="{
          customer: { name: 'John' },
          customers: {
            users: [
              { name: 'John', image: '/assets/avatar.png' },
              { name: 'John', image: '/assets/avatar.png' },
              { name: 'John', image: '/assets/avatar.png' },
              { name: 'John', image: '/assets/avatar.png' },
              { name: 'John', image: '/assets/avatar.png' },
              { name: 'John', image: '/assets/avatar.png' },
            ],
          },
        }"></div>
    </div>
    <app-box-tab
      class="w-100"
      tabId="myTab"
      Size="large"
      [boxContent]="orderTab"
      (selectedTab)="onTabSelected($event)">
      <ng-template #detailTab>
        <div class="d-flex flex-column spacing-24 position-relative">
          <div class="d-flex flex-column spacing-24">
            <app-overlay-summary></app-overlay-summary>
            <app-details [dropdown_panel]="dropdown_panel"></app-details>
          </div>
        </div>
      </ng-template>

      <ng-template #ordersTab>
        <app-order-tab></app-order-tab>
      </ng-template>

      <ng-template #activityTab>
        <app-activity-tab></app-activity-tab>
      </ng-template>
    </app-box-tab>
  </ng-container>

  <ng-container smallOverlay>
    <!--use small sidebar here-->
  </ng-container>
</app-overlay-layout>
<ng-template #skip let-data>
  <mat-dialog-content>
    <app-modal [modalDetail]="data" (customClick)="closeModal()"></app-modal>
  </mat-dialog-content>
</ng-template>
<ng-template #pause let-data>
  <mat-dialog-content>
    <app-modal class="w-100" [modalDetail]="data" (customClick)="closeModal()"></app-modal>
  </mat-dialog-content>
</ng-template>
<ng-template #stop let-data>
  <mat-dialog-content>
    <app-modal class="w-100" [modalDetail]="data" (customClick)="closeModal()"></app-modal>
  </mat-dialog-content>
</ng-template>
<ng-template #frequency let-data>
  <mat-dialog-content>
    <app-modal [modalDetail]="data" (customClick)="closeModal()"></app-modal>
  </mat-dialog-content>
</ng-template>
<ng-template #billing let-data>
  <mat-dialog-content>
    <app-modal [modalDetail]="data" (customClick)="closeModal()"></app-modal>
  </mat-dialog-content>
</ng-template>
