import { Injectable, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalCommandsService {
  private shortcuts = new Map<string, string>([
    ['ctrl+k', 'openSearch'],
    ['ctrl+m', 'command2'],
    ['backspace', 'clearSearch'],
    // add more shortcuts as needed
  ]);

  public shortcutTriggered: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.listenForShortcuts().subscribe(shortcut => {
      const command = this.shortcuts.get(shortcut);
      if (command) {
        this.executeCommand(command);
        this.shortcutTriggered.emit(command); // Emit the triggered command
      }
    });
  }

  listenForShortcuts() {
    return fromEvent<KeyboardEvent>(document, 'keydown').pipe(
      map(event => this.normalizeShortcut(event)),
      filter(shortcut => this.shortcuts.has(shortcut))
    );
  }

  private normalizeShortcut(event: KeyboardEvent): string {
    const keys = [];
    if (event.ctrlKey || event.metaKey) {
      keys.push('ctrl');
    }
    if (event.shiftKey) {
      keys.push('shift');
    }
    if (event.altKey) {
      keys.push('alt');
    }
    keys.push(event.key.toLowerCase());
    return keys.join('+');
  }

  // These commands are on global level.
  private executeCommand(command: string) {
    switch (command) {
      case 'command1':
        this.executeCommand1();
        break;
      case 'command2':
        this.executeCommand2();
        break;
      case 'clearSearch':
        this.executeClearSearch();
        break;
      default:
        console.warn(`Unhandled command: ${command}`);
    }
  }

  private executeCommand1() {
    console.log('Executing Command 1');
    // logic for command1
  }

  private executeCommand2() {
    console.log('Executing Command 2');
    // logic for command2
  }
  private executeClearSearch() {
    console.log('Clearing search');
    // global logic for handling Backspace key
  }
}
